export const FB01_EXTRA_CardList=[
{
    cardId:"FB01-033",
    name:"時とばし",
    color:"赤",
    rarity:"C",
    cost:"3",
    image:["/image/FB01/FB01-033.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-034",
    name:"魔封波",
    color:"赤",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-034.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-032",
    name:"力の大会の武舞台",
    color:"赤",
    rarity:"R",
    cost:"2",
    image:["/image/FB01/FB01-032.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-031",
    name:"第6宇宙の強者たち",
    color:"赤",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-031.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-030",
    name:"集結!第7宇宙代表",
    color:"赤",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-030.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-065",
    name:"タイムマシン",
    color:"青",
    rarity:"UC",
    cost:"1",
    image:["/image/FB01/FB01-065.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-068",
    name:"禍々しき大鎌",
    color:"青",
    rarity:"R",
    cost:"3",
    image:["/image/FB01/FB01-068.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-069",
    name:"禍々しき分身",
    color:"青",
    rarity:"R",
    cost:"3",
    image:["/image/FB01/FB01-069.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-135",
    name:"クラッシャーボール",
    color:"黄",
    rarity:"UC",
    cost:"1",
    image:["/image/FB01/FB01-135.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-138",
    name:"みんなそろってギニュー特戦隊!!!!",
    color:"黄",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-138.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-134",
    name:"クウラ機甲戦隊!!八ッ!!",
    color:"黄",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-134.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-137",
    name:"ボディチェンジ",
    color:"黄",
    rarity:"R",
    cost:"1",
    image:["/image/FB01/FB01-137.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-064",
    name:"絶望の刃",
    color:"青",
    rarity:"UC",
    cost:"2",
    image:["/image/FB01/FB01-064.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-066",
    name:"ファイナルホープスラッシュ",
    color:"青",
    rarity:"R",
    cost:"1",
    image:["/image/FB01/FB01-066.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-099",
    name:"アイビーム",
    color:"緑",
    rarity:"UC",
    cost:"1",
    image:["/image/FB01/FB01-099.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-102",
    name:"Dr.ゲロの研究所",
    color:"緑",
    rarity:"R",
    cost:"3",
    image:["/image/FB01/FB01-102.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-136",
    name:"スーパーノヴァ",
    color:"黄",
    rarity:"R",
    cost:"1",
    image:["/image/FB01/FB01-136.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-100",
    name:"姉弟のコンビネーション",
    color:"緑",
    rarity:"UC",
    cost:"1",
    image:["/image/FB01/FB01-100.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-103",
    name:"ビッグ・バン・アタック",
    color:"緑",
    rarity:"UC",
    cost:"2",
    image:["/image/FB01/FB01-103.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-067",
    name:"ブラックかめはめ波",
    color:"青",
    rarity:"UC",
    cost:"1",
    image:["/image/FB01/FB01-067.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-101",
    name:"瞬間移動かめはめ波",
    color:"緑",
    rarity:"C",
    cost:"0",
    image:["/image/FB01/FB01-101.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-133",
    name:"ギニュー特戦隊の絆",
    color:"黄",
    rarity:"R",
    cost:"1",
    image:["/image/FB01/FB01-133.webp"],
    count:0,
    type:"EXTRA",
    pack:"FB01",
    infinite:false
},
]