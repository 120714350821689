import React from 'react';
import '../index.css';
function ContactPage  () {
  const handleUneiShare = () => {
    const twitterUrl = `https://twitter.com/Pricha_card`;
    window.open(twitterUrl, '_blank');
  };

    return(
        <div className="content">
        <h1>お問い合わせ</h1>
        <div className='text-format'>当「非公式:ドラゴンボール&nbsp;フュージョンワールド&nbsp;デッキ構築ガイド」をご覧いただきまして誠にありがとうございます。<br/>
        何かお気付きの点やご質問等ございましたら、以下のTwitterへご連絡いただけると幸いです。
        </div>
        <img src="Twitter_logo.webp" alt="ロゴ" className='logo-image2' onClick={handleUneiShare}/>
      </div>
  );
}

  export default ContactPage;