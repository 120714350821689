import React from 'react';
import '../index.css';
import './PageStyle.css';


function SupportNavigation  () {
  const handleDBFWTwitter = () => {
    const twitterUrl = `https://twitter.com/DBFW_unei`;
    window.open(twitterUrl, '_blank');
  };

  const handleUneiShare = () => {
    const twitterUrl = `https://twitter.com/Pricha_card`;
    window.open(twitterUrl, '_blank');
  };

  const handleyoutubeShare = () => {
    const twitterUrl = `https://www.youtube.com/channel/UC-KTyU2ITAwxtZMGnnQV3dg`;
    window.open(twitterUrl, '_blank');
  };

  return(
    <div className="localNavigation">
        <div className="shareText">フォローして活動を応援する</div>
         <img src="DBFWunei_logo.webp" alt="大会Twitter" className='icon-logo-image' onClick={handleDBFWTwitter} />
         <img src="Twitter_logo.webp" alt="サイトTwitter" className='icon-logo-image' onClick={handleUneiShare}/>
         <img src="Youtube_logo.webp" alt="Youtube" className='icon-logo-image' onClick={handleyoutubeShare}/>
    </div>
  );
}

  export default SupportNavigation;