export const FP_CardList=[

    {
        cardId:"FP-001",
        name:"孫悟空",
        color:"赤",
        rarity:"PR",
        cost:"2",
        image:["/image/FP/FP-001.webp","/image/FP/FP-001-2.webp"],
        count:0,
        type:"BATTLE",
        pack:"FP",
        infinite:false
    },
    {
        cardId:"FP-002",
        name:"ベジータ",
        color:"青",
        rarity:"PR",
        cost:"3",
        image:["/image/FP/FP-002.webp"],
        count:0,
        type:"BATTLE",
        pack:"FP",
        infinite:false
    },
    {
        cardId:"FP-003",
        name:"ブロリー",
        color:"緑",
        rarity:"PR",
        cost:"6",
        image:["/image/FP/FP-003.webp"],
        count:0,
        type:"BATTLE",
        pack:"FP",
        infinite:false
    },
    {
        cardId:"FP-004",
        name:"フリーザ",
        color:"黄",
        rarity:"PR",
        cost:"2",
        image:["/image/FP/FP-004.webp"],
        count:0,
        type:"BATTLE",
        pack:"FP",
        infinite:false
    },
    {
        cardId:"FP-005",
        name:"超サイヤ人への覚醒",
        color:"赤",
        rarity:"PR",
        cost:"2",
        image:["/image/FP/FP-005.webp"],
        count:0,
        type:"EXTRA",
        pack:"FP",
        infinite:false
    },
    {
        cardId:"FP-006",
        name:"孫悟空",
        color:"赤",
        rarity:"PR",
        cost:"2",
        image:["/image/FP/FP-006.webp"],
        count:0,
        type:"BATTLE",
        pack:"FP",
        infinite:false
    },
    {
        cardId:"FP-007",
        name:"ベジータ",
        color:"青",
        rarity:"PR",
        cost:"2",
        image:["/image/FP/FP-007.webp"],
        count:0,
        type:"BATTLE",
        pack:"FP",
        infinite:false
    },
    {
        cardId:"FP-008",
        name:"孫悟空",
        color:"緑",
        rarity:"PR",
        cost:"4",
        image:["/image/FP/FP-008.webp"],
        count:0,
        type:"BATTLE",
        pack:"FP",
        infinite:false
    },
    {
        cardId:"FP-009",
        name:"ピッコロ",
        color:"赤",
        rarity:"PR",
        cost:"5",
        image:["/image/FP/FP-009.webp"],
        count:0,
        type:"BATTLE",
        pack:"FP",
        infinite:false
    },
    {
        cardId:"FP-010",
        name:"ベジット",
        color:"青",
        rarity:"PR",
        cost:"3",
        image:["/image/FP/FP-010.webp"],
        count:0,
        type:"BATTLE",
        pack:"FP",
        infinite:false
    },
    {
        cardId:"FP-011",
        name:"セル",
        color:"緑",
        rarity:"PR",
        cost:"3",
        image:["/image/FP/FP-011.webp"],
        count:0,
        type:"BATTLE",
        pack:"FP",
        infinite:false
    },
    {
        cardId:"FP-012",
        name:"ブルマ",
        color:"黄",
        rarity:"PR",
        cost:"2",
        image:["/image/FP/FP-012.webp"],
        count:0,
        type:"BATTLE",
        pack:"FP",
        infinite:false
    },
    {
        cardId:"FP-013",
        name:"孫悟飯:青年期",
        color:"赤",
        rarity:"PR",
        cost:"4",
        image:["/image/FP/FP-013.webp"],
        count:0,
        type:"BATTLE",
        pack:"FP",
        infinite:false
    },
    {
        cardId:"FP-014",
        name:"ブロリー:BR",
        color:"緑",
        rarity:"PR",
        cost:"7",
        image:["/image/FP/FP-014.webp"],
        count:0,
        type:"BATTLE",
        pack:"FP",
        infinite:false
    },
    {
        cardId:"FP-015",
        name:"ゴクウブラック",
        color:"青",
        rarity:"PR",
        cost:"4",
        image:["/image/FP/FP-015.webp"],
        count:0,
        type:"BATTLE",
        pack:"FP",
        infinite:false
    },
]