export const FS05_CardList=[
    {
        cardId:"FS05-01",
        name:"バーダック",
        color:"黒",
        rarity:"L",
        cost:"L",
        image:["/image/FS/FS05/FS05-01.webp","/image/FS/FS05/FS05-01-2.webp"],
        count:0,
        type:"LEADER",
        pack:"FS05",
        infinite:false
    },
    {
        cardId:"FS05-02",
        name:"大猿バーダック",
        color:"黒",
        rarity:"C",
        cost:"4",
        image:["/image/FS/FS05/FS05-02.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS05",
        infinite:false
    },
    {
        cardId:"FS05-03",
        name:"カカロット",
        color:"黒",
        rarity:"SR",
        cost:"1",
        image:["/image/FS/FS05/FS05-03.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS05",
        infinite:false
    },
    {
        cardId:"FS05-04",
        name:"セリパ",
        color:"黒",
        rarity:"C",
        cost:"1",
        image:["/image/FS/FS05/FS05-04.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS05",
        infinite:false
    },
    {
        cardId:"FS05-05",
        name:"孫悟飯",
        color:"黒",
        rarity:"C",
        cost:"7",
        image:["/image/FS/FS05/FS05-05.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS05",
        infinite:false
    },
    {
        cardId:"FS05-06",
        name:"トーマ",
        color:"黒",
        rarity:"C",
        cost:"2",
        image:["/image/FS/FS05/FS05-06.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS05",
        infinite:false
    },
    {
        cardId:"FS05-07",
        name:"トテッポ",
        color:"黒",
        rarity:"C",
        cost:"1",
        image:["/image/FS/FS05/FS05-07.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS05",
        infinite:false
    },
    {
        cardId:"FS05-08",
        name:"ドドリア",
        color:"黒",
        rarity:"C",
        cost:"2",
        image:["/image/FS/FS05/FS05-08.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS05",
        infinite:false
    },
    {
        cardId:"FS05-09",
        name:"ナッパ：青年期",
        color:"黒",
        rarity:"C",
        cost:"1",
        image:["/image/FS/FS05/FS05-09.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS05",
        infinite:false
    },
    {
        cardId:"FS05-10",
        name:"バーダック",
        color:"黒",
        rarity:"C",
        cost:"3",
        image:["/image/FS/FS05/FS05-10.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS05",
        infinite:false
    },
    {
        cardId:"FS05-11",
        name:"バーダック",
        color:"黒",
        rarity:"SR",
        cost:"5",
        image:["/image/FS/FS05/FS05-11.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS05",
        infinite:false
    },
    {
        cardId:"FS05-12",
        name:"パンブーキン",
        color:"黒",
        rarity:"C",
        cost:"2",
        image:["/image/FS/FS05/FS05-12.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS05",
        infinite:false
    },
    {
        cardId:"FS05-13",
        name:"フリーザ",
        color:"黒",
        rarity:"C",
        cost:"4",
        image:["/image/FS/FS05/FS05-13.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS05",
        infinite:false
    },
    {
        cardId:"FS05-14",
        name:"ベジータ：幼年期",
        color:"黒",
        rarity:"C",
        cost:"2",
        image:["/image/FS/FS05/FS05-14.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS05",
        infinite:false
    },
    {
        cardId:"FS05-15",
        name:"運命への抵抗",
        color:"黒",
        rarity:"C",
        cost:"1",
        image:["/image/FS/FS05/FS05-15.webp"],
        count:0,
        type:"EXTRA",
        pack:"FS05",
        infinite:false
    },
    {
        cardId:"FS05-16",
        name:"たったひとりの最終決戦",
        color:"黒",
        rarity:"C",
        cost:"1",
        image:["/image/FS/FS05/FS05-16.webp"],
        count:0,
        type:"EXTRA",
        pack:"FS05",
        infinite:false
    },
]