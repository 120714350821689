export const FB01_LEADER_CardList=[
{
    cardId:"FB01-071",
    name:"孫悟飯:少年期",
    color:"緑",
    rarity:"L",
    cost:"L",
    image:["/image/FB01/FB01-071.webp","/image/FB01/FB01-071-2.webp"],
    count:0,
    type:"LEADER",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-104",
    name:"ギニュー",
    color:"黄",
    rarity:"L",
    cost:"L",
    image:["/image/FB01/FB01-104.webp","/image/FB01/FB01-104-2.webp"],
    count:0,
    type:"LEADER",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-105",
    name:"クウラ",
    color:"黄",
    rarity:"L",
    cost:"L",
    image:["/image/FB01/FB01-105.webp","/image/FB01/FB01-105-2.webp"],
    count:0,
    type:"LEADER",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-002",
    name:"ビルス",
    color:"赤",
    rarity:"L",
    cost:"L",
    image:["/image/FB01/FB01-002.webp","/image/FB01/FB01-002-2.webp"],
    count:0,
    type:"LEADER",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-001",
    name:"孫悟空",
    color:"赤",
    rarity:"L",
    cost:"L",
    image:["/image/FB01/FB01-001.webp","/image/FB01/FB01-001-2.webp"],
    count:0,
    type:"LEADER",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-035",
    name:"ゴクウブラック",
    color:"青",
    rarity:"L",
    cost:"L",
    image:["/image/FB01/FB01-035.webp","/image/FB01/FB01-035-2.webp"],
    count:0,
    type:"LEADER",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-070",
    name:"人造人間17号",
    color:"緑",
    rarity:"L",
    cost:"L",
    image:["/image/FB01/FB01-070.webp","/image/FB01/FB01-070-2.webp"],
    count:0,
    type:"LEADER",
    pack:"FB01",
    infinite:false
},

{
    cardId:"FB01-036",
    name:"トランクス:未来",
    color:"青",
    rarity:"L",
    cost:"L",
    image:["/image/FB01/FB01-036.webp","/image/FB01/FB01-036-2.webp"],
    count:0,
    type:"LEADER",
    pack:"FB01",
    infinite:false
},

]