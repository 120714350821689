import { FS01_CardList } from "./FS/FS01";
import { FS02_CardList } from "./FS/FS02";
import { FS03_CardList } from "./FS/FS03";
import { FS04_CardList } from "./FS/FS04";
import { FS05_CardList } from "./FS/FS05";
import { FB01_BATTLE_CardList } from "./FB01/FB01_BATTLE";
import { FB01_LEADER_CardList} from "./FB01/FB01_LEADER";
import { FB01_EXTRA_CardList } from "./FB01/FB01_EXTRA";
import { FB02_BATTLE_CardList } from "./FB02/FB02_BATTLE";
import { FB02_LEADER_CardList} from "./FB02/FB02_LEADER";
import { FB02_EXTRA_CardList} from "./FB02/FB02_EXTRA";
import { FB03_BATTLE_CardList } from "./FB03/FB03_BATTLE";
import { FB03_LEADER_CardList} from "./FB03/FB03_LEADER";
import { FB03_EXTRA_CardList} from "./FB03/FB03_EXTRA";
import { FP_CardList } from "./FP/FP";


export const CardList=FS01_CardList.concat(FS02_CardList, FS03_CardList, FS04_CardList,FS05_CardList,FB02_BATTLE_CardList,FB02_LEADER_CardList,FB02_EXTRA_CardList,
    FB01_BATTLE_CardList,FB01_LEADER_CardList,FB01_EXTRA_CardList,FP_CardList,FB03_BATTLE_CardList,FB03_LEADER_CardList,FB03_EXTRA_CardList).sort((a, b) => {
        // cardIdを"-"で分割してそれぞれの部分を取得
        const [prefixA, numberA] = a.cardId.split('-');
        const [prefixB, numberB] = b.cardId.split('-');

        // まずは接頭辞で比較
        if (prefixA < prefixB) return -1;
        if (prefixA > prefixB) return 1;

        // 接頭辞が同じ場合は数字部分で比較
        // 数字部分を整数に変換
        const numA = parseInt(numberA, 10);
        const numB = parseInt(numberB, 10);

        return numA - numB;
    });