import React from 'react';
import '../index.css';
import { TwitterIcon,LineIcon } from 'react-share'; // react-shareからTwitterShareButtonをインポート

function LocalNavigation  () {
  const shareUrl = 'https://www.dbfw-deckbuilder.com'; // 共有するURLを設定
  const title = 'ドラゴンボールフュージョンワールド\n非公式:デッキ構築サイト\n#フュージョンワールド\n';
  

  const handleTwitterShare = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(title)}`;
    window.open(twitterUrl, '_blank');
  };

  const handleLineShare = () => {
    const LinerUrl = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(shareUrl)}`;
     // スマートフォンの場合（iOSまたはAndroid）
  if (/iPad|iPhone|iPod|android/i.test(navigator.userAgent)) {
    const lineAppUrl = `line://msg/text/?${encodeURIComponent(shareUrl)}`;
    window.location.href = lineAppUrl;
  } 
  // それ以外の場合は通常のウェブリンクを使用
  else {
    window.open(LinerUrl, '_blank');
  }
  };
  return(
    <div className="localNavigation">
      <div className="shareText">シェアする</div>
      {/* Twitterシェアボタン */}
      <TwitterIcon onClick={handleTwitterShare} className="icon" />
      {/* 間隔を開けるためのスペーサー */}
      <div className="spacer" />
      {/* LINEシェアボタン */}
      <LineIcon onClick={handleLineShare} className="icon" />
    
    </div>
  );
}

  export default LocalNavigation;