import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import decksData from '../js/decks.json'; // JSON データのインポート
import {Deck} from '../types/types'
import './ListStyle.css'
import { Helmet } from 'react-helmet';

const DeckList = () => {
    const [decks, setDecks] = useState<Deck[]>(decksData as Deck[]);

    useEffect(() => {
        setDecks(decksData);
    }, []);

    return (
        <div>

            <Helmet>
                <title>デッキレシピ一覧 - ドラゴンボール フュージョンワールド デッキ 構築 ガイド</title>
                <meta name="description" content="デッキレシピを提供するページです。"/>
                {/* ここに追加で構造化データなどのマークアップを挿入できます */}
            </Helmet>
            <div className="card mb-4">
            <h1>デッキレシピ一覧</h1>
            </div>
            <div className="deck-detail-list-2">
                {decks.map(deck => (
                    <div key={deck.deckName} className="deck-detail-2">
                        <h2>{deck.deckName}</h2>
                        <img src={deck.image} alt={deck.deckName} />
                        <Link to={`/deck-List/${encodeURIComponent(deck.deckName)}`}>詳細を見る</Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DeckList;
