import React, { useState } from 'react';
import "./CardStyle.css"
import { DeckRecipeNameProps } from './CardInterface';


function DeckRecipeName({ onFormDataChange }:DeckRecipeNameProps) {

    // フォームの値を状態として管理
    const [formData, setFormData] = useState({
      tournamentName: '',
      deckName: '',
      author: '',
      account: '',
    });
  

   // フォームが変更されたときに状態を更新
   const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // 更新されたフォームデータを親コンポーネントに渡す
    onFormDataChange({
      ...formData, // 最新のフォームデータを渡す
      [name]: value, // 変更されたフォームの値を反映する
    });
  };

return (
   <>
        <form>
            <div className="form-group">
                <label>大会名やサブタイトル:</label>
                <input
                    type="text"
                    name="tournamentName" // フォームの名前を指定
                    placeholder="かめはめ杯"
                    value={formData.tournamentName}
                    onChange={handleFormChange} // フォームが変更されたときに呼び出すコールバックを指定
                />
            </div>
            <div className="form-group" >
                <label>デッキ名:</label>
                <input
                    type="text"
                    name="deckName" // フォームの名前を指定
                    placeholder="孫悟空"
                    value={formData.deckName}
                    onChange={handleFormChange} // フォームが変更されたときに呼び出すコールバックを指定
                />
            </div>
            <div className="form-group" >
                <label>作者:</label>
                <input
                    type="text"
                    name="author" // フォームの名前を指定
                    placeholder="ぷりにき"
                    value={formData.author}
                    onChange={handleFormChange} // フォームが変更されたときに呼び出すコールバックを指定
                />
            </div>
            <div className="form-group">
                <label>アカウント:</label>
                <input
                    type="text"
                    name="account" // フォームの名前を指定
                    placeholder="@Pricha_card"
                    value={formData.account}
                    onChange={handleFormChange} // フォームが変更されたときに呼び出すコールバックを指定
                />
            </div>
        </form>
        </>
);
}

export default DeckRecipeName;
