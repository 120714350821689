import React from 'react';
import '../index.css';
import './PageStyle.css';
function GlobalNavigation () {
    return(
      <header className="global-header">
      <div className="header-content">
        <a href="/" className="logo">
        非公式:DBFW&nbsp;ガイド
          <img src="/Twitter_logo.webp" alt="Logo" className="logo-image" /></a>
        <nav className="global-nav">
          <ul>
            <li><a href="/tool/deck-builder">デッキ構築</a></li>
            <li><a href="/deck-List">デッキ一覧</a></li>
            <li><a href="/goods-List">商品一覧</a></li>
            <li><a href="/news">お知らせ</a></li>
            <li><a href="/contact">お問い合わせ</a></li>
            <li><a href="/privacy-policy">プライバシーポリシー</a></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

  export default GlobalNavigation ;