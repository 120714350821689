export interface CardItem {
    cardId: string;
    name: string;
    color: string;
    rarity: string;
    cost: string;
    image: string[];
    count: number;
    type:string;
    pack:string
}
  
export interface CardsProps {
    CardList: CardItem[];
    increment: (cardId: string) => void;
    decrement: (cardId: string) => void;
    countToFour: (cardId: string) => void;
    resetCount: (cardId: string) => void;
    resetPagination: boolean; // この行を追加
}

// Propsの型を定義
export interface DeckRecipeNameProps {
    onFormDataChange: (formData: any) => void; // プロパティの型情報を追加
}

  
export interface SelectedCardsProps {
    CardList: CardItem[];
    formData: {
      tournamentName: string;
      deckName: string;
      author: string;
      account: string;
      comment: string;
    }; 
}
  
// 集計情報を保持するためのインターフェイスを定義
export  interface AggregatedInfo {
    [key: string]: number;
}

export  interface TextItem {
    text: string;
    fontSize: number; // フォントサイズの追加
    color: string; // 色の追加
    marginBottom: number;
}

// cards.ts
export const cardTypes = ["LEADER","BATTLE", "EXTRA"];
export const cardCosts = ["0","1", "2", "3", "4", "5", "6", "7", "8"];
export const cardColors = ["赤", "青", "緑", "黄","黒"];
export const cardPacks = ["FP","FB01","FB02","FB03","FS01", "FS02", "FS03", "FS04","FS05"];
export const cardRaritys = ["PR","L", "SCR", "SR","R", "UC", "C"];
