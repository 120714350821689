export const FB02_EXTRA_CardList=[
    {
        cardId:"FB02-035",
        name:"身勝手の極意の片鱗",
        color:"赤",
        rarity:"C",
        cost:"2",
        image:["/image/FB02/FB02-035.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-069",
        name:"時の指輪",
        color:"青",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-069.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-103",
        name:"仙豆",
        color:"緑",
        rarity:"C",
        cost:"2",
        image:["/image/FB02/FB02-103.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-136",
        name:"蒼き力の覚醒",
        color:"黄",
        rarity:"UC",
        cost:"2",
        image:["/image/FB02/FB02-136.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-033",
        name:"窮地を救うバリア",
        color:"赤",
        rarity:"R",
        cost:"1",
        image:["/image/FB02/FB02-033.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-034",
        name:"強者の激突",
        color:"赤",
        rarity:"C",
        cost:"2",
        image:["/image/FB02/FB02-034.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-064",
        name:"神の鉄槌",
        color:"青",
        rarity:"C",
        cost:"2",
        image:["/image/FB02/FB02-064.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-065",
        name:"裁きの刃",
        color:"青",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-065.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-066",
        name:"邪悪なる力の融合",
        color:"青",
        rarity:"R",
        cost:"3",
        image:["/image/FB02/FB02-066.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-067",
        name:"超サイヤ人ロゼへの覚醒",
        color:"青",
        rarity:"C",
        cost:"3",
        image:["/image/FB02/FB02-067.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-068",
        name:"絶対の雷",
        color:"青",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-068.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-100",
        name:"完全体への覚醒",
        color:"緑",
        rarity:"UC",
        cost:"7",
        image:["/image/FB02/FB02-100.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-101",
        name:"ジンジャータウンの惨劇",
        color:"緑",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-101.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-102",
        name:"セルゲーム会場",
        color:"緑",
        rarity:"R",
        cost:"4",
        image:["/image/FB02/FB02-102.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-104",
        name:"地球を破壊する一撃",
        color:"緑",
        rarity:"R",
        cost:"2",
        image:["/image/FB02/FB02-104.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-137",
        name:"巻き戻される時間",
        color:"黄",
        rarity:"UC",
        cost:"1",
        image:["/image/FB02/FB02-137.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-138",
        name:"連続エネルギー弾",
        color:"黄",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-138.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB02",
        infinite:false
    },
]