export const FS04_CardList=[
    {
        cardId:"FS04-02",
        name:"キュイ",
        color:"黄",
        rarity:"C",
        cost:"3",
        image:["/image/FS/FS04/FS04-02.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS04",
        infinite:false
    },
    {
        cardId:"FS04-09",
        name:"チライ:BR",
        color:"黄",
        rarity:"C",
        cost:"7",
        image:["/image/FS/FS04/FS04-09.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS04",
        infinite:false
    },
    {
        cardId:"FS04-06",
        name:"シサミ",
        color:"黄",
        rarity:"C",
        cost:"4",
        image:["/image/FS/FS04/FS04-06.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS04",
        infinite:false
    },
    {
        cardId:"FS04-08",
        name:"タゴマ",
        color:"黄",
        rarity:"C",
        cost:"2",
        image:["/image/FS/FS04/FS04-08.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS04",
        infinite:false
    },
    {
        cardId:"FS04-11",
        name:"フリーザ",
        color:"黄",
        rarity:"C",
        cost:"1",
        image:["/image/FS/FS04/FS04-11.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS04",
        infinite:false
    },
    {
        cardId:"FS04-10",
        name:"ドドリア",
        color:"黄",
        rarity:"C",
        cost:"1",
        image:["/image/FS/FS04/FS04-10.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS04",
        infinite:false
    },

    {
        cardId:"FS04-12",
        name:"フリーザ",
        color:"黄",
        rarity:"C",
        cost:"2",
        image:["/image/FS/FS04/FS04-12.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS04",
        infinite:false
    },
    {
        cardId:"FS04-07",
        name:"ソルベ",
        color:"黄",
        rarity:"C",
        cost:"1",
        image:["/image/FS/FS04/FS04-07.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS04",
        infinite:false
    },
    {
        cardId:"FS04-14",
        name:"レモ:BR",
        color:"黄",
        rarity:"C",
        cost:"1",
        image:["/image/FS/FS04/FS04-14.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS04",
        infinite:false
    },
    {
        cardId:"FS04-05",
        name:"ザーボン",
        color:"黄",
        rarity:"C",
        cost:"2",
        image:["/image/FS/FS04/FS04-05.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS04",
        infinite:false
    },
    {
        cardId:"FS04-03",
        name:"ゴールデンフリーザ",
        color:"黄",
        rarity:"SR",
        cost:"5",
        image:["/image/FS/FS04/FS04-03.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS04",
        infinite:false
    },
    {
        cardId:"FS04-04",
        name:"コルド大王",
        color:"黄",
        rarity:"SR",
        cost:"4",
        image:["/image/FS/FS04/FS04-04.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS04",
        infinite:false
    },
    {
        cardId:"FS04-13",
        name:"フリーザ",
        color:"黄",
        rarity:"C",
        cost:"3",
        image:["/image/FS/FS04/FS04-13.webp"],
        count:0,
        type:"BATTLE",
        pack:"FS04",
        infinite:false
    },
    {
        cardId:"FS04-15",
        name:"ゴールデンデスビーム",
        color:"黄",
        rarity:"C",
        cost:"1",
        image:["/image/FS/FS04/FS04-15.webp"],
        count:0,
        type:"EXTRA",
        pack:"FS04",
        infinite:false
    },
    {
        cardId:"FS04-16",
        name:"デスボール",
        color:"黄",
        rarity:"C",
        cost:"1",
        image:["/image/FS/FS04/FS04-16.webp"],
        count:0,
        type:"EXTRA",
        pack:"FS04",
        infinite:false
    },
    {
        cardId:"FS04-01",
        name:"フリーザ",
        color:"黄",
        rarity:"L",
        cost:"L",
        image:["/image/FS/FS04/FS04-01.webp","/image/FS/FS04/FS04-01-2.webp"],
        count:0,
        type:"LEADER",
        pack:"FS04",
        infinite:false
    },

]