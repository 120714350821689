import React,{useState} from 'react';
import './PageStyle.css';
import {announcements} from './announcements';

function AnnouncementsPage () {

  const [currentPage, setCurrentPage] = useState(1);
  const announcementsPerPage = 10;

  // Calculate the current announcements
  const indexOfLastAnnouncement = currentPage * announcementsPerPage;
  const indexOfFirstAnnouncement = indexOfLastAnnouncement - announcementsPerPage;
  const currentAnnouncements = announcements.slice(indexOfFirstAnnouncement, indexOfLastAnnouncement);

  // Change page
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
    return (
        <div>
          <h1>お知らせ</h1>
          <div className="pagination">
                <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(announcements.length / announcementsPerPage)}>Next</button>
            </div>
          <ul>
            {currentAnnouncements.map(announcement => (
              <li key={announcement.id}>
                <h4>{announcement.title}</h4>
                <p><strong>Date:</strong> {announcement.date}</p>
                <p dangerouslySetInnerHTML={{ __html: announcement.content }}></p>
          </li>
            ))}
          </ul>
        </div>
      );
}

  export default AnnouncementsPage ;