import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {Card} from '../types/types'
import '../AllDeckList/ListStyle.css';
import { CardList } from '../CardsList/CardList';
import Slider from 'react-slick'; // react-slick をインポート
import packData from '../js/shops.json'; // JSONデータのインポート

const GoodsDetail: React.FC = () => { 
    const { packName } = useParams<{ packName: string }>();
    const [filteredCards, setFilteredCards] = useState<Card[]>([]); // 明示的に型注釈を付ける
    const [showDeckList, setShowDeckList] = useState<boolean>(false); // 状態管理用の追加
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImages, setSelectedImages] = useState<string[]>([]);
    const [packImage, setPackImage] = useState<string>('');

    const handleImageClick = (images: string[]) => {
        setSelectedImages(images); // 複数の画像を設定
        setIsModalOpen(true);
      };

      const ImageModal = () => {
        const settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true
        };
      const closeModal = () => {
        setIsModalOpen(false);
      };
      return (
        <div className={`modal ${isModalOpen ? 'show' : ''}`} tabIndex={-1} style={{ display: isModalOpen ? 'block' : 'none' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <Slider {...settings}>
                  {selectedImages.map((image, index) => (
                    <div key={index}>
                      <img src={image} className="img-fluid" alt={`Card number ${index + 1}`} />
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>閉じる</button>
              </div>
            </div>
          </div>
        </div>
      );
    };

    useEffect(() => {
        const filtered = CardList.filter(card => card.pack === packName);
        setFilteredCards(filtered);
          // packNameに一致するオブジェクトをJSONデータから探す
          const matchingPack = packData.find(pack => pack.packName === packName);
          if (matchingPack) {
              setPackImage(matchingPack.image);
          }
      }, [packName]);

    return (
        <div className="deck-detail-container-2">
            {filteredCards ? (
                <div>
                    <div className="card mb-4">
                    <h1>{packName} パックの詳細</h1>
                    </div>
                    <p>【ドラゴンボールフュージョンワールド】【{packName}】のカード内容を紹介します！</p>
                    <div className="card mb-4">
                    <h2>商品画像</h2>
                    </div>
                    {packImage && <img src={packImage} alt={`${packName}の画像`} style={{ width: '50%', height: '50%' }} />}
                    <div className="card mb-4">
                    <h2>収録内容</h2>
                    </div>
                    <button onClick={() => setShowDeckList(!showDeckList)}>
                        カードリスト
                    </button>
                        {showDeckList && (
                           <div className="deck-list-container">
                           <table className="deck-list-table">
                             <thead>
                               <tr>
                                 <th>カードNo</th>
                                 <th>カード名</th>
                                 <th>タイプ</th>
                                 <th>コスト</th>
                                 <th>レアリティ</th>
                                 <th>色</th>
                               </tr>
                             </thead>
                             <tbody>
                             {filteredCards.map((card, index) => (
                                        <tr key={index}>
                                            <td>{card.cardId}</td>
                                            <td>{card.name}</td>
                                            <td>{card.type}</td>
                                            <td>{card.cost}</td>
                                            <td>{card.rarity}</td>
                                            <td>{card.color}</td>
                                        </tr>
                                    ))}
                             </tbody>
                           </table>
                         </div>
                        )}
                    <div className="card mb-4">
                    <h2>カード画像</h2>
                    </div>
                    <div className="deck-cards-container-2">
                    {filteredCards.map((card, index) => (
                                <div key={index} className="card-container-2" onClick={() => handleImageClick(card.image)}>
                                    <img src={card.image[0]} alt={card.name} />
                                    <h3>{card.cardId}</h3>
                                    <h3>{card.name}</h3>
                                    <h3>{card.rarity}</h3>
                                </div>
                            ))}
                    </div>
                    <ImageModal />

                </div>
            ) : (
                <p>デッキが見つかりません</p>
            )}
        </div>
    );
};

export default GoodsDetail;