export const FB03_EXTRA_CardList=[
    {
        cardId:"FB03-138",
        name:"理性を持った大猿",
        color:"黒",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-138.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-076",
        name:"スカウター",
        color:"緑",
        rarity:"C",
        cost:"2",
        image:["/image/FB03/FB03-076.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-051",
        name:"グレートサイヤマンだ!!!",
        color:"青",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-051.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-100",
        name:"パッパラパー!!!",
        color:"黄",
        rarity:"C",
        cost:"2",
        image:["/image/FB03/FB03-100.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-026",
        name:"破壊玉",
        color:"赤",
        rarity:"C",
        cost:"2",
        image:["/image/FB03/FB03-026.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-050",
        name:"オレンジスターハイスクール",
        color:"青",
        rarity:"R",
        cost:"1",
        image:["/image/FB03/FB03-050.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-077",
        name:"魔貫光殺砲",
        color:"緑",
        rarity:"R",
        cost:"5",
        image:["/image/FB03/FB03-077.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-102",
        name:"ファイナルエクスプロージョン",
        color:"黄",
        rarity:"R",
        cost:"3",
        image:["/image/FB03/FB03-102.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-137",
        name:"10倍かめはめ波",
        color:"黒",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-137.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-023",
        name:"終わりだ!!",
        color:"赤",
        rarity:"R",
        cost:"2",
        image:["/image/FB03/FB03-023.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-024",
        name:"空前絶後の超決戦!",
        color:"赤",
        rarity:"UC",
        cost:"5",
        image:["/image/FB03/FB03-024.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-025",
        name:"ダブルキャノンマキシマム",
        color:"赤",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-025.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-052",
        name:"悟飯くんの好み？",
        color:"青",
        rarity:"UC",
        cost:"1",
        image:["/image/FB03/FB03-052.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-075",
        name:"修業の日々",
        color:"緑",
        rarity:"UC",
        cost:"3",
        image:["/image/FB03/FB03-075.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-098",
        name:"アメ玉になっちゃえ!!!",
        color:"黄",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-098.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-099",
        name:"エネルギー吸収",
        color:"黄",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-099.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-101",
        name:"バビディの宇宙船",
        color:"黄",
        rarity:"R",
        cost:"2",
        image:["/image/FB03/FB03-101.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-103",
        name:"封印されし魔人",
        color:"黄",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-103.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-135",
        name:"新たなる伝説",
        color:"黒",
        rarity:"UC",
        cost:"4",
        image:["/image/FB03/FB03-135.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-136",
        name:"希望の宇宙船",
        color:"黒",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-136.webp"],
        count:0,
        type:"EXTRA",
        pack:"FB03",
        infinite:false
    },
]