import React from 'react';
import '../index.css';
function PrivacyPolicy  () {

    return(<>
        <h1>プライバシーポリシー</h1>
        <br/>
        <h2>広告について</h2>
        当サイトでは、第三者配信の広告サービス（Googleアドセンス、A8.net）を利用しており、ユーザーの興味に応じた商品やサービスの広告を表示するため、クッキー（Cookie）を使用しております。
        <br/>
        <br/>
        クッキーを使用することで当サイトはお客様のコンピュータを識別できるようになりますが、お客様個人を特定できるものではありません。
        <br/>
        <br/>
        
        Cookieを無効にする方法やGoogleアドセンスに関する詳細は「広告 – ポリシーと規約 – Google」をご確認ください。
        <br/>
        <br/>
        またDBFW 非公式デッキ構築サイトは、Amazon.co.jpを宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラムである、Amazonアソシエイト・プログラムの参加者です。
        <br/>
        <br/>
        <h2>アクセス解析ツールについて</h2>
        当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
        <br/>
        <br/>
        このGoogleアナリティクスはトラフィックデータの収集のためにクッキー（Cookie）を使用しております。トラフィックデータは匿名で収集されており、個人を特定するものではありません。
        <br/>
        <br/>
        <h2>免責事項</h2>
        <br/>
        当ブログからのリンクやバナーなどで移動したサイトで提供される情報、サービス等について一切の責任を負いません。
        <br/>
        <br/>
        また当ブログのコンテンツ・情報について、できる限り正確な情報を提供するように努めておりますが、正確性や安全性を保証するものではありません。
        <br/>
        情報が古くなっていることもございます。
        <br/>
        <br/>
        当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
        </>
  );
}

  export default PrivacyPolicy;