export const FS02_CardList=[
    
{
    cardId:"FS02-07",
    name:"孫悟飯:青年期",
    color:"青",
    rarity:"C",
    cost:"3",
    image:["/image/FS/FS02/FS02-07.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS02",
    infinite:false
},

{
    cardId:"FS02-15",
    name:"ギャリック砲",
    color:"青",
    rarity:"C",
    cost:"1",
    image:["/image/FS/FS02/FS02-15.webp"],
    count:0,
    type:"EXTRA",
    pack:"FS02",
    infinite:false
},
{
    cardId:"FS02-10",
    name:"ビーデル",
    color:"青",
    rarity:"C",
    cost:"7",
    image:["/image/FS/FS02/FS02-10.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS02",
    infinite:false
},

{
    cardId:"FS02-16",
    name:"ファイナルフラッシュ",
    color:"青",
    rarity:"C",
    cost:"2",
    image:["/image/FS/FS02/FS02-16.webp"],
    count:0,
    type:"EXTRA",
    pack:"FS02",
    infinite:false
},
{
    cardId:"FS02-12",
    name:"ブルマ",
    color:"青",
    rarity:"C",
    cost:"1",
    image:["/image/FS/FS02/FS02-12.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS02",
    infinite:false
},
{
    cardId:"FS02-08",
    name:"トランクス:幼年期",
    color:"青",
    rarity:"C",
    cost:"1",
    image:["/image/FS/FS02/FS02-08.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS02",
    infinite:false
},
{
    cardId:"FS02-05",
    name:"孫悟天",
    color:"青",
    rarity:"C",
    cost:"1",
    image:["/image/FS/FS02/FS02-05.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS02",
    infinite:false
},
{
    cardId:"FS02-01",
    name:"ベジータ",
    color:"青",
    rarity:"L",
    cost:"L",
    image:["/image/FS/FS02/FS02-01.webp","/image/FS/FS02/FS02-01-2.webp"],
    count:0,
    type:"LEADER",
    pack:"FS02",
    infinite:false
},
{
    cardId:"FS02-03",
    name:"ゴテンクス",
    color:"青",
    rarity:"SR",
    cost:"4",
    image:["/image/FS/FS02/FS02-03.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS02",
    infinite:false
},
{
    cardId:"FS02-13",
    name:"ベジータ",
    color:"青",
    rarity:"SR",
    cost:"5",
    image:["/image/FS/FS02/FS02-13.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS02",
    infinite:false
},
{
    cardId:"FS02-09",
    name:"トランクス:幼年期",
    color:"青",
    rarity:"C",
    cost:"2",
    image:["/image/FS/FS02/FS02-09.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS02",
    infinite:false
},
{
    cardId:"FS02-02",
    name:"ゴテンクス",
    color:"青",
    rarity:"C",
    cost:"3",
    image:["/image/FS/FS02/FS02-02.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS02",
    infinite:false
},
{
    cardId:"FS02-06",
    name:"孫悟天",
    color:"青",
    rarity:"C",
    cost:"2",
    image:["/image/FS/FS02/FS02-06.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS02",
    infinite:false
},
{
    cardId:"FS02-11",
    name:"ピッコロ",
    color:"青",
    rarity:"C",
    cost:"3",
    image:["/image/FS/FS02/FS02-11.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS02",
    infinite:false
},
{
    cardId:"FS02-04",
    name:"孫悟空",
    color:"青",
    rarity:"C",
    cost:"4",
    image:["/image/FS/FS02/FS02-04.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS02",
    infinite:false
},
{
    cardId:"FS02-14",
    name:"魔人ブウ:善",
    color:"青",
    rarity:"C",
    cost:"2",
    image:["/image/FS/FS02/FS02-14.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS02",
    infinite:false
},
]