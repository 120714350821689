export const FB02_BATTLE_CardList=[
    {
        cardId:"FB02-016",
        name:"人造人間18号",
        color:"赤",
        rarity:"R",
        cost:"2",
        image:["/image/FB02/FB02-016.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },

    {
        cardId:"FB02-013",
        name:"ケフラ",
        color:"赤",
        rarity:"SR",
        cost:"4",
        image:["/image/FB02/FB02-013.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },

    {
        cardId:"FB02-043",
        name:"ザマス",
        color:"青",
        rarity:"SR",
        cost:"2",
        image:["/image/FB02/FB02-043.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-081",
        name:"スパイロボット",
        color:"緑",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-081.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-088",
        name:"孫悟飯:少年期",
        color:"緑",
        rarity:"UC",
        cost:"6",
        image:["/image/FB02/FB02-088.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-091",
        name:"トランクス:未来",
        color:"緑",
        rarity:"SR",
        cost:"3",
        image:["/image/FB02/FB02-091.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-130",
        name:"ブルマ",
        color:"黄",
        rarity:"SR",
        cost:"1",
        image:["/image/FB02/FB02-130.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-019",
        name:"天津飯",
        color:"赤",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-019.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-032",
        name:"ロージィ",
        color:"赤",
        rarity:"C",
        cost:"2",
        image:["/image/FB02/FB02-032.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },

    {
        cardId:"FB02-118",
        name:"孫悟空",
        color:"黄",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-118.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-121",
        name:"孫悟飯:青年期",
        color:"黄",
        rarity:"C",
        cost:"2",
        image:["/image/FB02/FB02-121.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },

    {
        cardId:"FB02-126",
        name:"ピッコロ",
        color:"黄",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-126.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },

    {
        cardId:"FB02-047",
        name:"シン",
        color:"青",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-047.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-048",
        name:"ズノー",
        color:"青",
        rarity:"C",
        cost:"2",
        image:["/image/FB02/FB02-048.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-060",
        name:"ベジット",
        color:"青",
        rarity:"UC",
        cost:"2",
        image:["/image/FB02/FB02-060.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-085",
        name:"セルジュニア",
        color:"緑",
        rarity:"R",
        cost:"2",
        image:["/image/FB02/FB02-085.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-015",
        name:"人造人間17号",
        color:"赤",
        rarity:"SR",
        cost:"3",
        image:["/image/FB02/FB02-015.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-030",
        name:"リブリアン",
        color:"赤",
        rarity:"UC",
        cost:"7",
        image:["/image/FB02/FB02-030.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-063",
        name:"老界王神",
        color:"青",
        rarity:"UC",
        cost:"7",
        image:["/image/FB02/FB02-063.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-075",
        name:"人造人間16号",
        color:"緑",
        rarity:"UC",
        cost:"7",
        image:["/image/FB02/FB02-075.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-115",
        name:"ジャコ",
        color:"黄",
        rarity:"UC",
        cost:"7",
        image:["/image/FB02/FB02-115.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-026",
        name:"ヘレス",
        color:"赤",
        rarity:"R",
        cost:"4",
        image:["/image/FB02/FB02-026.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-044",
        name:"ザマス:合体",
        color:"青",
        rarity:"SR",
        cost:"4",
        image:["/image/FB02/FB02-044.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-109",
        name:"亀仙人",
        color:"黄",
        rarity:"R",
        cost:"3",
        image:["/image/FB02/FB02-109.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-040",
        name:"ゴクウブラック",
        color:"青",
        rarity:"R",
        cost:"4",
        image:["/image/FB02/FB02-040.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-093",
        name:"パラガス:BR",
        color:"緑",
        rarity:"R",
        cost:"2",
        image:["/image/FB02/FB02-093.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-133",
        name:"ベジータ",
        color:"黄",
        rarity:"SR",
        cost:"3",
        image:["/image/FB02/FB02-133.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },

    {
        cardId:"FB02-029",
        name:"ラムーシ",
        color:"赤",
        rarity:"UC",
        cost:"5",
        image:["/image/FB02/FB02-029.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-049",
        name:"全王",
        color:"青",
        rarity:"R",
        cost:"6",
        image:["/image/FB02/FB02-049.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-084",
        name:"セル",
        color:"緑",
        rarity:"SR",
        cost:"8",
        image:["/image/FB02/FB02-084.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-128",
        name:"フリーザ",
        color:"黄",
        rarity:"R",
        cost:"3",
        image:["/image/FB02/FB02-128.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    
    },
    {
        cardId:"FB02-077",
        name:"人造人間17号/人造人間18号",
        color:"緑",
        rarity:"R",
        cost:"4",
        image:["/image/FB02/FB02-077.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-018",
        name:"孫悟飯:青年期",
        color:"赤",
        rarity:"SR",
        cost:"3",
        image:["/image/FB02/FB02-018.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },

    {
        cardId:"FB02-089",
        name:"孫悟飯:未来",
        color:"緑",
        rarity:"SR",
        cost:"5",
        image:["/image/FB02/FB02-089.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-119",
        name:"孫悟空",
        color:"黄",
        rarity:"SR",
        cost:"4",
        image:["/image/FB02/FB02-119.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-139",
        name:"ベジット",
        color:"青",
        rarity:"SCR",
        cost:"5",
        image:["/image/FB02/FB02-139.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-140",
        name:"ゴールデンフリーザ",
        color:"黄",
        rarity:"SCR",
        cost:"6",
        image:["/image/FB02/FB02-140.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-002",
        name:"アニラーザ",
        color:"赤",
        rarity:"C",
        cost:"4",
        image:["/image/FB02/FB02-002.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-003",
        name:"カクンサ",
        color:"赤",
        rarity:"UC",
        cost:"4",
        image:["/image/FB02/FB02-003.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-004",
        name:"カトペスラ",
        color:"赤",
        rarity:"R",
        cost:"3",
        image:["/image/FB02/FB02-004.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-005",
        name:"ガノス",
        color:"赤",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-005.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-006",
        name:"カリフラ",
        color:"赤",
        rarity:"C",
        cost:"3",
        image:["/image/FB02/FB02-006.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-007",
        name:"カリフラ",
        color:"赤",
        rarity:"R",
        cost:"2",
        image:["/image/FB02/FB02-007.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-008",
        name:"キテラ",
        color:"赤",
        rarity:"R",
        cost:"2",
        image:["/image/FB02/FB02-008.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-009",
        name:"キャウェイ",
        color:"赤",
        rarity:"C",
        cost:"2",
        image:["/image/FB02/FB02-009.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-010",
        name:"キャベ",
        color:"赤",
        rarity:"C",
        cost:"3",
        image:["/image/FB02/FB02-010.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-011",
        name:"ケール",
        color:"赤",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-011.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-012",
        name:"ケフラ",
        color:"赤",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-012.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-014",
        name:"シドラ",
        color:"赤",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-014.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-017",
        name:"孫悟空",
        color:"赤",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-017.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-020",
        name:"バジル",
        color:"赤",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-020.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-021",
        name:"パパロニ",
        color:"赤",
        rarity:"UC",
        cost:"1",
        image:["/image/FB02/FB02-021.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-022",
        name:"ピッコロ",
        color:"赤",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-022.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-023",
        name:"フリーザ",
        color:"赤",
        rarity:"R",
        cost:"1",
        image:["/image/FB02/FB02-023.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-024",
        name:"ベジータ",
        color:"赤",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-024.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-025",
        name:"ベルガモ",
        color:"赤",
        rarity:"C",
        cost:"2",
        image:["/image/FB02/FB02-025.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-027",
        name:"ムリチム",
        color:"赤",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-027.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-028",
        name:"ラペンダ",
        color:"赤",
        rarity:"C",
        cost:"2",
        image:["/image/FB02/FB02-028.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-031",
        name:"リブリアン",
        color:"赤",
        rarity:"R",
        cost:"4",
        image:["/image/FB02/FB02-031.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-037",
        name:"キビト",
        color:"青",
        rarity:"UC",
        cost:"2",
        image:["/image/FB02/FB02-037.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },

    {
        cardId:"FB02-038",
        name:"グレートサイヤマン",
        color:"青",
        rarity:"C",
        cost:"3",
        image:["/image/FB02/FB02-038.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-039",
        name:"ゴクウブラック",
        color:"青",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-039.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-041",
        name:"ゴテンクス",
        color:"青",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-041.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-042",
        name:"ザマス",
        color:"青",
        rarity:"R",
        cost:"1",
        image:["/image/FB02/FB02-042.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-045",
        name:"ザマス:合体",
        color:"青",
        rarity:"UC",
        cost:"5",
        image:["/image/FB02/FB02-045.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-046",
        name:"ザマス:合体",
        color:"青",
        rarity:"R",
        cost:"6",
        image:["/image/FB02/FB02-046.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-050",
        name:"孫悟空",
        color:"青",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-050.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-051",
        name:"孫悟空",
        color:"青",
        rarity:"UC",
        cost:"4",
        image:["/image/FB02/FB02-051.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-052",
        name:"孫悟天",
        color:"青",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-052.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-053",
        name:"トランクス:未来",
        color:"青",
        rarity:"C",
        cost:"2",
        image:["/image/FB02/FB02-053.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-054",
        name:"ビーデル",
        color:"青",
        rarity:"R",
        cost:"1",
        image:["/image/FB02/FB02-054.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-055",
        name:"ビルス",
        color:"青",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-055.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-056",
        name:"ブルマ",
        color:"青",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-056.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-057",
        name:"ベジータ",
        color:"青",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-057.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-058",
        name:"ベジータ",
        color:"青",
        rarity:"R",
        cost:"4",
        image:["/image/FB02/FB02-058.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-059",
        name:"ベジータ",
        color:"青",
        rarity:"UC",
        cost:"4",
        image:["/image/FB02/FB02-059.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-061",
        name:"ベジット",
        color:"青",
        rarity:"SR",
        cost:"3",
        image:["/image/FB02/FB02-061.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-062",
        name:"マイ:未来",
        color:"青",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-062.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-071",
        name:"神様",
        color:"緑",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-071.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-072",
        name:"カリン",
        color:"緑",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-072.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-073",
        name:"カロニー/ピーザ/ピロシキ",
        color:"緑",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-073.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-074",
        name:"クリリン",
        color:"緑",
        rarity:"R",
        cost:"2",
        image:["/image/FB02/FB02-074.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-076",
        name:"人造人間17号",
        color:"緑",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-076.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-078",
        name:"人造人間18号",
        color:"緑",
        rarity:"C",
        cost:"2",
        image:["/image/FB02/FB02-078.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-079",
        name:"人造人間19号",
        color:"緑",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-079.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-080",
        name:"人造人間20号",
        color:"緑",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-080.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-082",
        name:"セル",
        color:"緑",
        rarity:"UC",
        cost:"2",
        image:["/image/FB02/FB02-082.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-083",
        name:"セル",
        color:"緑",
        rarity:"C",
        cost:"3",
        image:["/image/FB02/FB02-083.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-086",
        name:"孫悟空",
        color:"緑",
        rarity:"R",
        cost:"3",
        image:["/image/FB02/FB02-086.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-087",
        name:"孫悟飯:少年期",
        color:"緑",
        rarity:"UC",
        cost:"2",
        image:["/image/FB02/FB02-087.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-090",
        name:"チチ",
        color:"緑",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-090.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-092",
        name:"トランクス:未来",
        color:"緑",
        rarity:"C",
        cost:"4",
        image:["/image/FB02/FB02-092.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-094",
        name:"ピッコロ",
        color:"緑",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-094.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-095",
        name:"ブルマ",
        color:"緑",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-095.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-096",
        name:"ブロリー:BR",
        color:"緑",
        rarity:"R",
        cost:"7",
        image:["/image/FB02/FB02-096.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-097",
        name:"ベジータ",
        color:"緑",
        rarity:"C",
        cost:"3",
        image:["/image/FB02/FB02-097.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-098",
        name:"ミスター・サタン",
        color:"緑",
        rarity:"R",
        cost:"2",
        image:["/image/FB02/FB02-098.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-099",
        name:"ヤムチャ",
        color:"緑",
        rarity:"C",
        cost:"2",
        image:["/image/FB02/FB02-099.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-106",
        name:"ウイス",
        color:"黄",
        rarity:"R",
        cost:"4",
        image:["/image/FB02/FB02-106.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-107",
        name:"ウイス",
        color:"黄",
        rarity:"UC",
        cost:"5",
        image:["/image/FB02/FB02-107.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-108",
        name:"亀仙人",
        color:"黄",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-108.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-110",
        name:"ギニュー",
        color:"黄",
        rarity:"R",
        cost:"2",
        image:["/image/FB02/FB02-110.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-111",
        name:"クウラ",
        color:"黄",
        rarity:"UC",
        cost:"1",
        image:["/image/FB02/FB02-111.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-112",
        name:"クリリン",
        color:"黄",
        rarity:"C",
        cost:"2",
        image:["/image/FB02/FB02-112.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-113",
        name:"ゴテンクス",
        color:"黄",
        rarity:"C",
        cost:"4",
        image:["/image/FB02/FB02-113.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-114",
        name:"コルド大王",
        color:"黄",
        rarity:"UC",
        cost:"4",
        image:["/image/FB02/FB02-114.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-116",
        name:"人造人間18号",
        color:"黄",
        rarity:"R",
        cost:"3",
        image:["/image/FB02/FB02-116.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-117",
        name:"孫悟空",
        color:"黄",
        rarity:"C",
        cost:"2",
        image:["/image/FB02/FB02-117.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-120",
        name:"孫悟天",
        color:"黄",
        rarity:"C",
        cost:"2",
        image:["/image/FB02/FB02-120.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-122",
        name:"孫悟飯:青年期",
        color:"黄",
        rarity:"R",
        cost:"3",
        image:["/image/FB02/FB02-122.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-123",
        name:"天津飯",
        color:"黄",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-123.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-124",
        name:"トランクス:幼年期",
        color:"黄",
        rarity:"C",
        cost:"2",
        image:["/image/FB02/FB02-124.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-125",
        name:"ビーデル",
        color:"黄",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-125.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-127",
        name:"ビルス",
        color:"黄",
        rarity:"C",
        cost:"3",
        image:["/image/FB02/FB02-127.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-129",
        name:"ビーデル",
        color:"黄",
        rarity:"C",
        cost:"1",
        image:["/image/FB02/FB02-129.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-131",
        name:"ベジータ",
        color:"黄",
        rarity:"C",
        cost:"3",
        image:["/image/FB02/FB02-131.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-132",
        name:"ベジータ",
        color:"黄",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-132.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-134",
        name:"メカフリーザ",
        color:"黄",
        rarity:"C",
        cost:"4",
        image:["/image/FB02/FB02-134.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-135",
        name:"メタルクウラ",
        color:"黄",
        rarity:"UC",
        cost:"3",
        image:["/image/FB02/FB02-135.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB02",
        infinite:false
    },
]