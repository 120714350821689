import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import shops from '../js/shops.json'; // JSON データのインポート
import {Packs} from '../types/types'
import '../AllDeckList/ListStyle.css'
import { Helmet } from 'react-helmet';

const GoodsList = () => {
    const [Goods, setGoods] = useState<Packs[]>(shops as Packs[]);

    useEffect(() => {
        setGoods(shops);
    }, []);

    return (
        <div>
            <Helmet>
                <title>商品一覧 - ドラゴンボール フュージョンワールド デッキ 構築 ガイド</title>
                <meta name="description" content="ドラゴンボール フュージョンワールドの商品を提供するページです。"/>
                {/* ここに追加で構造化データなどのマークアップを挿入できます */}
            </Helmet>
            <div className="card mb-4">
            <h1>商品一覧</h1>
            </div>
            <div className="deck-detail-list-2">
                {Goods.map(good => (
                    <div key={good.packName} className="deck-detail-2">
                        <h2>{good.packName}</h2>
                        <img src={good.image} alt={good.packName} />
                        <Link to={`/goods-List/${encodeURIComponent(good.packName)}`}>詳細を見る</Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GoodsList;
