import React from 'react';
// スタイルシートをインポート
import './index.css';
import GlobalNavigation from './Page/GlobalNavigation';
import LocalNavigation from './Page/LocalNavigation';
import CardDeckBuilder from './DecksScripts/CardDeckBuilder';
import ContactPage from './Page/ContactPage';
import SupportNavigation from './Page/SupportNavigation';
import AnnouncementsPage from './Page/AnnouncementsPage';
import PrivacyPolicy from './Page/PrivacyPolicy';
import DeckList from './AllDeckList/DeckList'; // DeckList コンポーネントのインポートパスを調整
import DeckDetail from './AllDeckList/DeckDetail'; // DeckDetail コンポーネントのインポートパスを調整
import Home from './Page/Home';
import ToolList from './AllToolList/ToolList';
import { ScrollToTop } from "./Page/ScrollToTop";
import GoodsList from './AllStoreList/GoodsList';
import GoodsDetail from './AllStoreList/GoodsDetail';
import EventNavigation from './Page/EventNavigation'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // React Routerのインポート
const App = () => (
    <Router>
    <>
    <GlobalNavigation />
    <SupportNavigation />
    <EventNavigation  />
    <ScrollToTop/>
      {/* ルートコンポーネント内でのルーティング */}
      <Routes>
      <Route path="/" element={<Home />}  />
        <Route path="/tool" element={<ToolList/>} />
        <Route path="/tool/deck-builder" element={<CardDeckBuilder/>} />
        <Route path="/deck-List" element={<DeckList />} />
        <Route path="/deck-List/:deckName" element={<DeckDetail />} />
        <Route path="/goods-List" element={<GoodsList/>} />
        <Route path="/goods-List/:packName" element={<GoodsDetail/>} />
        <Route path="/contact" element={<ContactPage/>} />
        <Route path="/news" element={<AnnouncementsPage/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
      </Routes>
    <LocalNavigation />
    </>
  </Router>
);
export default App;