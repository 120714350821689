import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import decksData from '../js/decks.json'; // JSON データのインポート
import tool from '../js/tool.json'; // JSON データのインポート
import {Deck,Tools} from '../types/types'
import shops from '../js/shops.json'; // JSON データのインポート
import {Packs} from '../types/types'
import './home.css'
import { Helmet } from 'react-helmet';

function Home  () {
    const [decks, setDecks] = useState<Deck[]>(decksData as Deck[]);
    const [tools, setTools] = useState<Tools[]>(tool as Tools[]);
    const [Goods, setGoods] = useState<Packs[]>(shops as Packs[]);

    useEffect(() => {
        // 最初の3つのデッキデータだけをセットする
        setDecks(decksData.slice(0, 3));
        setTools(tool.slice(0, 3));
        setGoods(shops.slice(0, 3));
    }, []);

    return (
        <div className="home-container">
            <Helmet>
                <title>デッキレシピ一覧 - ドラゴンボール フュージョンワールド デッキ 構築 ガイド</title>
                <meta name="description" content="デッキレシピ、ツール、商品の詳細情報を提供するページです。"/>
                {/* ここに追加で構造化データなどのマークアップを挿入できます */}
            </Helmet>
            <div className="card mb-4">
            <h1>デッキレシピ一覧</h1>
            </div>
            <div className="home-deck-detail-list">
                {decks.map(deck => (
                <Link to={`/deck-List/${encodeURIComponent(deck.deckName)}`} className="home-deck-detail" key={deck.deckName} style={{ textDecoration: 'none' }}>
                        <h2>{deck.deckName}</h2>
                        <img src={deck.image} alt={deck.deckName} />
                </Link>
                ))}
            </div>
                <Link to="/deck-List" className="link-button">もっと見る</Link>
            

             <div className="card mb-4">
            <h1>ツール一覧</h1>
            </div>
            <div className="home-deck-detail-list">
                {tools.map(tool => (
                    <Link to={tool.url}className="home-deck-detail" key={tool.toolName} style={{ textDecoration: 'none' }}>
                        <h2>{tool.toolName}</h2>
                        <img src={tool.image} alt={tool.toolName} />
                    </Link>
                ))}
            </div>
            <Link to="/tool" className="link-button">もっと見る</Link>

            <div className="card mb-4">
            <h1>商品一覧</h1>
            </div>
            <div className="home-deck-detail-list">
                {Goods.map(good => (
                    <Link to={`/goods-List/${encodeURIComponent(good.packName)}`}className="home-deck-detail" key={good.packName} style={{ textDecoration: 'none' }}>
                        <h2>{good.packName}</h2>
                        <img src={good.image} alt={good.packName} />
                        </Link>

                ))}
            </div>
            <Link to="/goods-List" className="link-button">もっと見る</Link>
        </div>
    );
}

  export default Home;