export const FB01_BATTLE_CardList=[
{
    cardId:"FB01-086",
    name:"孫悟空",
    color:"緑",
    rarity:"UC",
    cost:"2",
    image:["/image/FB01/FB01-086.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false

},
{
    cardId:"FB01-074",
    name:"人造人間16号",
    color:"緑",
    rarity:"UC",
    cost:"6",
    image:["/image/FB01/FB01-074.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false

},

{
    cardId:"FB01-081",
    name:"人造人間18号",
    color:"緑",
    rarity:"R",
    cost:"4",
    image:["/image/FB01/FB01-081.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false

},
{
    cardId:"FB01-077",
    name:"人造人間17号",
    color:"緑",
    rarity:"UC",
    cost:"4",
    image:["/image/FB01/FB01-077.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false

},
{
    cardId:"FB01-063",
    name:"ヤジロベー:未来",
    color:"青",
    rarity:"UC",
    cost:"7",
    image:["/image/FB01/FB01-063.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-046",
    name:"孫悟空",
    color:"青",
    rarity:"R",
    cost:"2",
    image:["/image/FB01/FB01-046.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-028",
    name:"ボタモ",
    color:"赤",
    rarity:"R",
    cost:"2",
    image:["/image/FB01/FB01-028.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-010",
    name:"サオネル",
    color:"赤",
    rarity:"C",
    cost:"3",
    image:["/image/FB01/FB01-010.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-058",
    name:"ベジータ",
    color:"青",
    rarity:"UC",
    cost:"3",
    image:["/image/FB01/FB01-058.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-037",
    name:"ゴクウブラック",
    color:"青",
    rarity:"R",
    cost:"3",
    image:["/image/FB01/FB01-037.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-115",
    name:"コルド大王",
    color:"黄",
    rarity:"C",
    cost:"4",
    image:["/image/FB01/FB01-115.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},

{
    cardId:"FB01-124",
    name:"ネイズ",
    color:"黄",
    rarity:"UC",
    cost:"2",
    image:["/image/FB01/FB01-124.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},

{
    cardId:"FB01-110",
    name:"ギニュー",
    color:"黄",
    rarity:"C",
    cost:"3",
    image:["/image/FB01/FB01-110.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},

{
    cardId:"FB01-132",
    name:"リクーム",
    color:"黄",
    rarity:"R",
    cost:"3",
    image:["/image/FB01/FB01-132.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-025",
    name:"フロスト",
    color:"赤",
    rarity:"R",
    cost:"3",
    image:["/image/FB01/FB01-025.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-020",
    name:"ピッコロ",
    color:"赤",
    rarity:"C",
    cost:"2",
    image:["/image/FB01/FB01-020.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-003",
    name:"ヴァドス",
    color:"赤",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-003.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
    
{
    cardId:"FB01-131",
    name:"メタルクウラ",
    color:"黄",
    rarity:"C",
    cost:"3",
    image:["/image/FB01/FB01-131.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:true
},
{
    cardId:"FB01-114",
    name:"グルド",
    color:"黄",
    rarity:"R",
    cost:"2",
    image:["/image/FB01/FB01-114.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-045",
    name:"シュウ",
    color:"青",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-045.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-106",
    name:"アプール",
    color:"黄",
    rarity:"C",
    cost:"2",
    image:["/image/FB01/FB01-106.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-053",
    name:"ババリ星人",
    color:"青",
    rarity:"C",
    cost:"2",
    image:["/image/FB01/FB01-053.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-091",
    name:"天津飯",
    color:"緑",
    rarity:"C",
    cost:"4",
    image:["/image/FB01/FB01-091.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-112",
    name:"クウラ",
    color:"黄",
    rarity:"C",
    cost:"3",
    image:["/image/FB01/FB01-112.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-006",
    name:"カリフラ",
    color:"赤",
    rarity:"UC",
    cost:"5",
    image:["/image/FB01/FB01-006.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-044",
    name:"ザマス",
    color:"青",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-044.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-085",
    name:"孫悟空",
    color:"緑",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-085.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-128",
    name:"フリーザ",
    color:"黄",
    rarity:"UC",
    cost:"2",
    image:["/image/FB01/FB01-128.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-029",
    name:"マゲッタ",
    color:"赤",
    rarity:"C",
    cost:"2",
    image:["/image/FB01/FB01-029.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-024",
    name:"フリーザ",
    color:"赤",
    rarity:"C",
    cost:"3",
    image:["/image/FB01/FB01-024.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-009",
    name:"ケール",
    color:"赤",
    rarity:"UC",
    cost:"3",
    image:["/image/FB01/FB01-009.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-038",
    name:"ゴクウブラック",
    color:"青",
    rarity:"C",
    cost:"4",
    image:["/image/FB01/FB01-038.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-015",
    name:"孫悟空",
    color:"赤",
    rarity:"SR",
    cost:"3",
    image:["/image/FB01/FB01-015.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},

{
    cardId:"FB01-089",
    name:"孫悟飯:少年期",
    color:"緑",
    rarity:"R",
    cost:"5",
    image:["/image/FB01/FB01-089.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-121",
    name:"チルド",
    color:"黄",
    rarity:"R",
    cost:"3",
    image:["/image/FB01/FB01-121.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-082",
    name:"人造人間19号",
    color:"緑",
    rarity:"C",
    cost:"2",
    image:["/image/FB01/FB01-082.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},

{
    cardId:"FB01-076",
    name:"人造人間17号",
    color:"緑",
    rarity:"C",
    cost:"3",
    image:["/image/FB01/FB01-076.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-011",
    name:"シャンパ",
    color:"赤",
    rarity:"C",
    cost:"4",
    image:["/image/FB01/FB01-011.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-013",
    name:"人造人間17号",
    color:"赤",
    rarity:"UC",
    cost:"2",
    image:["/image/FB01/FB01-013.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-073",
    name:"人造人間16号",
    color:"緑",
    rarity:"UC",
    cost:"3",
    image:["/image/FB01/FB01-073.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-017",
    name:"大神官",
    color:"赤",
    rarity:"UC",
    cost:"3",
    image:["/image/FB01/FB01-017.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-042",
    name:"ザマス",
    color:"青",
    rarity:"UC",
    cost:"1",
    image:["/image/FB01/FB01-042.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-116",
    name:"ザーボン",
    color:"黄",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-116.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-048",
    name:"孫悟空",
    color:"青",
    rarity:"C",
    cost:"3",
    image:["/image/FB01/FB01-048.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-111",
    name:"キュイ",
    color:"黄",
    rarity:"UC",
    cost:"2",
    image:["/image/FB01/FB01-111.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-080",
    name:"人造人間18号",
    color:"緑",
    rarity:"C",
    cost:"3",
    image:["/image/FB01/FB01-080.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-022",
    name:"ピリナ",
    color:"赤",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-022.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-126",
    name:"バータ",
    color:"黄",
    rarity:"C",
    cost:"2",
    image:["/image/FB01/FB01-126.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-056",
    name:"ピラフ",
    color:"青",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-056.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-060",
    name:"ベジータ",
    color:"青",
    rarity:"C",
    cost:"4",
    image:["/image/FB01/FB01-060.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-083",
    name:"人造人間20号",
    color:"緑",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-083.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-107",
    name:"ギニュー",
    color:"黄",
    rarity:"UC",
    cost:"1",
    image:["/image/FB01/FB01-107.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-007",
    name:"キャべ",
    color:"赤",
    rarity:"UC",
    cost:"7",
    image:["/image/FB01/FB01-007.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-093",
    name:"トランクス:未来",
    color:"緑",
    rarity:"UC",
    cost:"2",
    image:["/image/FB01/FB01-093.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-019",
    name:"Dr.ロタ",
    color:"赤",
    rarity:"C",
    cost:"2",
    image:["/image/FB01/FB01-019.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-108",
    name:"ギニュー",
    color:"黄",
    rarity:"R",
    cost:"2",
    image:["/image/FB01/FB01-108.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-088",
    name:"孫悟飯:少年期",
    color:"緑",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-088.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-054",
    name:"ハル/マキ",
    color:"青",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-054.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-097",
    name:"ミスター・サタン",
    color:"緑",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-097.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-041",
    name:"ゴワス",
    color:"青",
    rarity:"C",
    cost:"2",
    image:["/image/FB01/FB01-041.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-123",
    name:"ドドリア",
    color:"黄",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-123.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-052",
    name:"トランクス:幼年期",
    color:"青",
    rarity:"UC",
    cost:"2",
    image:["/image/FB01/FB01-052.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-094",
    name:"ピッコロ",
    color:"緑",
    rarity:"C",
    cost:"2",
    image:["/image/FB01/FB01-094.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-095",
    name:"ベジータ",
    color:"緑",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-095.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},

{
    cardId:"FB01-117",
    name:"サウザー",
    color:"黄",
    rarity:"C",
    cost:"2",
    image:["/image/FB01/FB01-117.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-018",
    name:"天津飯",
    color:"赤",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-018.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-055",
    name:"パン",
    color:"青",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-055.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-072",
    name:"クリリン",
    color:"緑",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-072.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-026",
    name:"フワ",
    color:"赤",
    rarity:"R",
    cost:"2",
    image:["/image/FB01/FB01-026.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},

{
    cardId:"FB01-122",
    name:"ドーレ",
    color:"黄",
    rarity:"C",
    cost:"2",
    image:["/image/FB01/FB01-122.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-057",
    name:"ブルマ",
    color:"青",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-057.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-127",
    name:"バナン",
    color:"黄",
    rarity:"UC",
    cost:"2",
    image:["/image/FB01/FB01-127.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-075",
    name:"人造人間17号",
    color:"緑",
    rarity:"C",
    cost:"2",
    image:["/image/FB01/FB01-075.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-061",
    name:"マイ:未来",
    color:"青",
    rarity:"UC",
    cost:"1",
    image:["/image/FB01/FB01-061.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-005",
    name:"亀仙人",
    color:"赤",
    rarity:"UC",
    cost:"1",
    image:["/image/FB01/FB01-005.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-120",
    name:"スーイ",
    color:"黄",
    rarity:"UC",
    cost:"3",
    image:["/image/FB01/FB01-120.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-016",
    name:"孫悟飯",
    color:"赤",
    rarity:"R",
    cost:"3",
    image:["/image/FB01/FB01-016.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-087",
    name:"孫悟空",
    color:"緑",
    rarity:"R",
    cost:"5",
    image:["/image/FB01/FB01-087.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-004",
    name:"ウイス",
    color:"赤",
    rarity:"UC",
    cost:"1",
    image:["/image/FB01/FB01-004.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},

{
    cardId:"FB01-119",
    name:"ジース",
    color:"黄",
    rarity:"UC",
    cost:"1",
    image:["/image/FB01/FB01-119.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},

{
    cardId:"FB01-059",
    name:"ベジータ",
    color:"青",
    rarity:"R",
    cost:"4",
    image:["/image/FB01/FB01-059.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-098",
    name:"ヤムチャ",
    color:"緑",
    rarity:"UC",
    cost:"7",
    image:["/image/FB01/FB01-098.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-049",
    name:"トランクス:未来",
    color:"青",
    rarity:"R",
    cost:"2",
    image:["/image/FB01/FB01-049.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-092",
    name:"トランクス:未来",
    color:"緑",
    rarity:"R",
    cost:"3",
    image:["/image/FB01/FB01-092.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-008",
    name:"クリリン",
    color:"赤",
    rarity:"R",
    cost:"4",
    image:["/image/FB01/FB01-008.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-090",
    name:"孫悟飯:少年期",
    color:"緑",
    rarity:"R",
    cost:"6",
    image:["/image/FB01/FB01-090.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-084",
    name:"人造人間20号",
    color:"緑",
    rarity:"R",
    cost:"3",
    image:["/image/FB01/FB01-084.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},

{
    cardId:"FB01-012",
    name:"シン",
    color:"赤",
    rarity:"UC",
    cost:"3",
    image:["/image/FB01/FB01-012.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-040",
    name:"ゴワス",
    color:"青",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-040.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-043",
    name:"ザマス",
    color:"青",
    rarity:"C",
    cost:"1",
    image:["/image/FB01/FB01-043.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-047",
    name:"孫悟空",
    color:"青",
    rarity:"UC",
    cost:"2",
    image:["/image/FB01/FB01-047.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-051",
    name:"トランクス:未来",
    color:"青",
    rarity:"R",
    cost:"4",
    image:["/image/FB01/FB01-051.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-027",
    name:"ベジータ",
    color:"赤",
    rarity:"R",
    cost:"2",
    image:["/image/FB01/FB01-027.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-014",
    name:"人造人間18号",
    color:"赤",
    rarity:"UC",
    cost:"2",
    image:["/image/FB01/FB01-014.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-118",
    name:"ザウザー",
    color:"黄",
    rarity:"C",
    cost:"3",
    image:["/image/FB01/FB01-118.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-125",
    name:"ネイル",
    color:"黄",
    rarity:"UC",
    cost:"7",
    image:["/image/FB01/FB01-125.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-130",
    name:"メカフリーザ",
    color:"黄",
    rarity:"R",
    cost:"5",
    image:["/image/FB01/FB01-130.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-129",
    name:"フリーザ",
    color:"黄",
    rarity:"SR",
    cost:"4",
    image:["/image/FB01/FB01-129.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-062",
    name:"マイ:未来",
    color:"青",
    rarity:"SR",
    cost:"1",
    image:["/image/FB01/FB01-062.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-113",
    name:"クウラ",
    color:"黄",
    rarity:"SR",
    cost:"4",
    image:["/image/FB01/FB01-113.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-079",
    name:"人造人間18号",
    color:"緑",
    rarity:"SR",
    cost:"2",
    image:["/image/FB01/FB01-079.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-078",
    name:"人造人間17号/人造人間18号",
    color:"緑",
    rarity:"SR",
    cost:"6",
    image:["/image/FB01/FB01-078.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-050",
    name:"トランクス:未来",
    color:"青",
    rarity:"SR",
    cost:"3",
    image:["/image/FB01/FB01-050.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-021",
    name:"ヒット",
    color:"赤",
    rarity:"SR",
    cost:"2",
    image:["/image/FB01/FB01-021.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-023",
    name:"ビルス",
    color:"赤",
    rarity:"SR",
    cost:"5",
    image:["/image/FB01/FB01-023.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-109",
    name:"ギニュー",
    color:"黄",
    rarity:"SR",
    cost:"2",
    image:["/image/FB01/FB01-109.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-096",
    name:"ベジータ",
    color:"緑",
    rarity:"SR",
    cost:"4",
    image:["/image/FB01/FB01-096.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-039",
    name:"ゴクウブラック",
    color:"青",
    rarity:"SR",
    cost:"6",
    image:["/image/FB01/FB01-039.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-139",
    name:"孫悟空",
    color:"赤",
    rarity:"SCR",
    cost:"4",
    image:["/image/FB01/FB01-139.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
{
    cardId:"FB01-140",
    name:"孫悟飯:少年期",
    color:"緑",
    rarity:"SCR",
    cost:"7",
    image:["/image/FB01/FB01-140.webp"],
    count:0,
    type:"BATTLE",
    pack:"FB01",
    infinite:false
},
]