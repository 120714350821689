import React, { useRef, useEffect, useCallback } from 'react';
import './CardStyle.css';
import { CardItem, SelectedCardsProps,AggregatedInfo,TextItem } from './CardInterface';



function SelectedCards({ CardList ,formData  }: SelectedCardsProps) {
  const canvasRef = useRef<HTMLCanvasElement|null>(null);


    // 背景画像をCanvasに描画する関数
  const drawBackground = (ctx:CanvasRenderingContext2D, callback:()=>void) => {
    const background = new Image();
    background.src = "/image/style/backSc.webp"; // 背景画像のパスを指定
    background.onload = () => {
      ctx.drawImage(background, 0, 0, ctx.canvas.width, ctx.canvas.height);
      callback(); // 背景画像のロード後にカードを描画するためのコールバック
    };
      // 画像がロードできない場合のエラーハンドリング
  background.onerror = () => {
    console.error("背景画像をロードできませんでした。");
  };
  };

  const drawLeaderCard = (ctx: CanvasRenderingContext2D, card: CardItem, formData: {
    tournamentName: string;
    deckName: string;
    author: string;
    account: string;
  }) => {
    // フォームデータが空かどうかを判断
    const isFormDataEmpty = !formData.tournamentName && !formData.deckName && !formData.author && !formData.account;
  
    // リーダーカードの描画位置を決定
    const xPos = isFormDataEmpty ? 1565 : 1730; // フォームデータが空の場合、デフォルト位置、そうでなければ左にシフト
    const yPos = isFormDataEmpty ? 828 : 925; // Y位置も調整可能
  
    // リーダーカードを描画する
    const image = new Image();
    image.onload = () => {
      ctx.drawImage(image, xPos, yPos, 220, 330);
    };
    image.src = card.image[0];
  };


  // 集計情報を計算する関数
  const calculateAggregatedInfo = (cards: CardItem[]): AggregatedInfo => {
    // costごとに集計するオブジェクトを初期化
    const aggregatedInfo: AggregatedInfo = {
      totalCost1: 0,
      totalCost2: 0,
      totalCost3: 0,
      totalCost4: 0,
      totalCost5: 0,
      totalCost6: 0,
      totalCost7: 0,
      totalCost8: 0,
      totalDeckCount: 0,
      totalExtraCardCount:0,
      totalBattleCount:0,
      totalLeaderCount: 0, // 新しく 'totalLeaderCount' を追加

    };
    // 各カードについてループ処理
  cards.forEach(card => {
    // costをキーとして使用し、そのcostのカードが既に集計されているかを確認
    if (card.type === 'LEADER') {
      // カードのタイプが 'LEADER' の場合、'totalLeaderCount' を増やす
      aggregatedInfo.totalLeaderCount += card.count;
    } else {
      const costKey = `totalCost${card.cost}`;
      if (!aggregatedInfo[costKey]) {
        aggregatedInfo[costKey] = 0; // まだそのcostのキーがなければ初期化
      }
      aggregatedInfo[costKey] += card.count; // そのcostのカウントに、現在のカードのcountを追加
    }

    // 'EXTRA' カードの処理
    if (card.type === 'BATTLE') {
      aggregatedInfo.totalBattleCount += card.count;
      aggregatedInfo['totalDeckCount'] += card.count;
    }

    // 'EXTRA' カードの処理
    if (card.type === 'EXTRA') {
      aggregatedInfo.totalExtraCardCount += card.count;
      aggregatedInfo['totalDeckCount'] += card.count;
    }

    // 新しいキー（totalDeckCount）の集計
    if (!aggregatedInfo['totalDeckCount']) {
      aggregatedInfo['totalDeckCount'] = 0;
    }
    
  });

    return aggregatedInfo;
};

const drawMultipleTextsInArea = (ctx:CanvasRenderingContext2D, texts:TextItem[], rect:{ x: number, y: number, width: number, height: number }) => {
  // 描画開始位置を記録する変数
  let drawStartY = rect.y;

  // 各テキストをループ処理
  texts.forEach((textItem,index) => {
        // フォントスタイルと色を設定
        ctx.font = `${textItem.fontSize}px Arial`;
        ctx.fillStyle = textItem.color;
    
        // テキスト描画関数を呼び出す前にテキストのメトリクスを取得
        let textMetrics = ctx.measureText(textItem.text);
        let fontSize = textItem.fontSize;
    
        // 描画エリアに収まるようにフォントサイズを調整
        while ((textMetrics.width > rect.width || textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent > rect.height) && fontSize > 0) {
          fontSize -= 1; // フォントサイズを減少
          ctx.font = `${fontSize}px Arial`; // 新しいフォントサイズを設定
          textMetrics = ctx.measureText(textItem.text); // メトリクスを再計算
        }
        if(index === 0 && textItem.text!==""){
          ctx.fillText(textItem.text, rect.x, drawStartY + 80 );
        }else if (index === 1 && textItem.text!=="") {
          // テキストを描画
        ctx.fillText(textItem.text, rect.x, drawStartY + 200 );
        ctx.font = `70px Arial`; // 新しいフォントサイズを設定
        ctx.fillText("デッキ", 1750, 300);
        }else if (index === 2 && textItem.text!=="") {
          // テキストを描画
        ctx.fillText(textItem.text, rect.x, drawStartY + 880  );
        ctx.font = `60px Arial`; // 新しいフォントサイズを設定
        ctx.fillText("デッキ作者", rect.x, drawStartY + 780  );
        }else if(index === 3 && textItem.text!==""){
        ctx.font = `${fontSize}px Arial`; // 新しいフォントサイズを設定
        // テキストを描画
        ctx.fillText(`(${textItem.text})`, rect.x, drawStartY+950);
        }else{
          ctx.font = `${fontSize}px Arial`; // 新しいフォントサイズを設定
          ctx.fillText(textItem.text, rect.x, drawStartY );
        }
       
    
  });
};

 // Canvasに集計情報を描画する関数
 const drawAggregatedInfo = useCallback((
  ctx:CanvasRenderingContext2D, 
  info:AggregatedInfo, 
  startX:number, 
  startY:number,
  formData: {
    tournamentName: string;
    deckName: string;
    author: string;
    account: string;
  }
  ) => {
  // 複数のテキストとその間のマージンを配列で管理
  const texts: TextItem[] = [
    { text: formData.tournamentName, marginBottom: 0,fontSize: 80, color: "white" },
    { text: formData.deckName, marginBottom: 0,fontSize: 100, color: "white" },
    { text: formData.author, marginBottom: 0 ,fontSize: 80, color: "white"},
    { text: formData.account, marginBottom: 0 ,fontSize: 50, color: "white"},
  ];
  const lineHeight = 60; // 行の高さ
  const lineWidth = 330; // 列
  // フォームデータの内容が空かどうかをチェック
  const hasFormData = texts.some(item => item.text !== "");
  const rectAdjustment = hasFormData  ? 0: 280;


  // データがあれば startY を 80 に、なければ 20 に設定
  startY = hasFormData ? 370 : 100;
  startX += hasFormData ? 80 : 280;

  const rect = { x: 1280 + rectAdjustment, y: 20, width: 680, height: 1240 };
   // テキスト背景を描画
   ctx.fillStyle = 'rgba(0, 0, 0,0.8)'; // 薄いグレーの背景
   ctx.fillRect(rect.x, rect.y, rect.width, rect.height);





  // 各情報を描画
  // 複数のテキストを描画する関数を呼び出し
  drawMultipleTextsInArea(ctx, texts, rect);
  // ctx.fillText(`${formData.tournamentName}`, startX, 100);
  // 初期フォントサイズを設定
  let fontSize = 50; // 仮の初期値、適宜調整してください。
  ctx.font = `${fontSize}px Arial`;
  // テキストのスタイルを設定
  ctx.fillStyle = 'white';

  // コスト情報を集計し、表示用の配列を作成
  let displayCosts = [];
  for (let i = 0; i <= 8; i++) {
    const costKey = `totalCost${i}`;
    if (info[costKey] > 0) {
      displayCosts.push({ cost: i, value: info[costKey] });
    }
  }
  let lastYLeft = startY, lastYRight = startY, isSecondColumnStarted = false;
  // Adjust Y position within column to allow up to 4 items in the first column
  displayCosts.forEach((cost, index) => {
    let columnOffset = hasFormData && index >= 4 ? lineWidth : 0;
    let currentY = startY + ((index % (hasFormData ? 4 : displayCosts.length)) * lineHeight); // 4つごとに新しい列、または1列で続ける
    ctx.fillText(`Cost ${cost.cost}: ${cost.value}`, startX + columnOffset, currentY);
    if (columnOffset === 0) {
      lastYLeft = currentY; // Update last Y position for left column
    } else {
      lastYRight = currentY; // Update last Y position for right column
      isSecondColumnStarted = true;
    }
  });
  // Determine the starting Y for Extra and Leader based on the last item in the longer column
  let extraStartY = (lastYRight > lastYLeft) ? lastYRight + lineHeight : lastYLeft + lineHeight;
  let columnOffsetForExtraLeader = isSecondColumnStarted ? lineWidth : 0;


  if (info.totalBattleCount > 0) {
  ctx.fillText(`Battle: ${info.totalBattleCount}`, startX +columnOffsetForExtraLeader, extraStartY);
  extraStartY  += lineHeight;
  }

  if (info.totalExtraCardCount > 0) {
  ctx.fillText(`Extra: ${info.totalExtraCardCount}`, startX +columnOffsetForExtraLeader, extraStartY);
  extraStartY  += lineHeight + 5;
}
  ctx.font = `40px Arial`;
  ctx.fillText(`Created by デッキ構築`, rect.x, 1200);
  ctx.font = `28px Arial`;

  ctx.fillText(`https://www.dbfw-deckbuilder.com`, rect.x, 1240);
  ctx.font = `${fontSize}px Arial`;

  if (info.totalDeckCount > 0) {
  if(info.totalDeckCount>=50 && info.totalDeckCount<= 60){
    ctx.fillStyle = 'white';
    ctx.fillText(`Total: ${info.totalDeckCount}`, startX +columnOffsetForExtraLeader, extraStartY );
  }else{
    ctx.fillStyle = 'Red';
    ctx.fillText(`Total: ${info.totalDeckCount}`, startX +columnOffsetForExtraLeader, extraStartY );
  }
}
},[]);
  

  // Canvasにカードを描画する関数
  const drawCards = (ctx:CanvasRenderingContext2D, cards:CardItem[],hasFormData: boolean) => {

     // フォームデータがある場合は最大6列、ない場合は7列を使用
    const maxCols = hasFormData ? 6 : 7;
    const filteredAndSortedCards = cards
    .filter(card => card.type === 'BATTLE' || card.type === 'EXTRA')
    .sort((a, b) => {
      if (a.type === b.type) {
        // 同じタイプの場合はコストで比較
        return parseInt(a.cost) - parseInt(b.cost);
      } else {
        // 異なるタイプの場合はBATTLEをEXTRAより前に配置
        return a.type === 'BATTLE' ? -1 : 1;
      }
    });

    filteredAndSortedCards.forEach((card, index) => {
      let cardWidth = 440; // デフォルトのカードの幅
      let cardHeight = 660; // デフォルトのカードの高さ
      let cols = Math.min(filteredAndSortedCards.length, maxCols); // 現在の列数
      let rows = Math.ceil(filteredAndSortedCards.length/ cols); // 現在の行数
      // キャンバスに収まるようにカードのサイズを調整
      const maxWidth = hasFormData ? 1280 / cols: 1560/cols;
      const maxHeight = 1280 / rows;
    if (cardWidth > maxWidth || cardHeight > maxHeight ) {
      const scale = Math.min(maxWidth / cardWidth, maxHeight / cardHeight);
      cardWidth *= scale;
      cardHeight *= scale;

    }
      const col = index % cols; // 現在の列
      const row = Math.floor(index / cols); // 現在の行
      const x = col * cardWidth;
      const y = row * cardHeight;
  
      // カード画像をロードして描画
      const image = new Image();
      image.onload = () => {
        ctx.drawImage(image, x, y, cardWidth, cardHeight);
  
        // カウントを描画
        if (card.count >= 1) {
          const fontSize = 100; // カウントのフォントサイズ
          ctx.font = `${fontSize}px Arial`;
          const text = card.count.toString();
          // テキストの描画位置を計算
          const textWidth = ctx.measureText(card.count.toString()).width;
          const textHeight = fontSize * 1.2; // 高さの余白を考慮

          // テキスト背景の位置
          const bgX = x + cardWidth - textWidth - 20; // カウントの位置から少し余白を取る
          const bgY = y + 38;
          const bgWidth = textWidth + 20; // テキスト幅に余白を加える
          const bgHeight = textHeight;
    
          // テキスト背景を描画
          ctx.fillStyle = 'rgba(0, 0, 0,0.8)'; // 薄いグレーの背景
          ctx.fillRect(bgX, bgY, bgWidth, bgHeight);


          // カウントのテキストを描画
          ctx.fillStyle = 'white';

          // テキストの外枠を描画
          ctx.strokeText(text, bgX + 10, bgY + fontSize);

          // テキストを描画
          ctx.fillText(text, bgX + 10, bgY + fontSize);
        }
      };
      image.src = card.image[0];
    });

  };


  // Canvasを初期化してカードを描画する
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas&&canvas.getContext) {
      const ctx = canvas.getContext('2d');
    if (ctx) {
    // Canvasのサイズを設定
    canvas.width = 1980;
    canvas.height = 1280;

    // 背景画像を描画
    drawBackground(ctx, () => {
      // 選択されたカードを描画
      const selectedCards = CardList.filter(card => card.count > 0);
      const hasFormData = [formData.tournamentName, formData.deckName, formData.author, formData.account].some(text => text !== "");
      
      drawCards(ctx, selectedCards,hasFormData);

      // LEADER カードを別の場所に描画
      selectedCards.forEach(card => {
        if (card.type === 'LEADER' && card.count === 1) {
          drawLeaderCard(ctx, card,formData);
        }
      });

      // 集計情報を計算
      const aggregatedInfo = calculateAggregatedInfo(selectedCards);

      // 集計情報をCanvasの右側に描画
      drawAggregatedInfo(ctx, aggregatedInfo, 1300, 400,
        {
          tournamentName: formData.tournamentName,
          deckName: formData.deckName,
          author: formData.author,
          account: formData.account,
        }); // 1500, 100 は情報を開始するX, Y座標
    });
 
    
    }
  }
  }, [CardList,formData,drawAggregatedInfo]); // CardListが更新されたら再描画

  // 画像として保存する機能
  const saveAsImage = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        // モバイルデバイスの場合の処理
        const image = canvas.toDataURL('image/png');
        const popup = window.open();
        if (popup) {
          popup.document.write(`<img src="${image}" style="width:100%;" />`);
          popup.document.title = '画像を長押しして保存';
          popup.document.close();
        } else {
          // ポップアップがブロックされた場合の処理
          alert('画像を保存するには、ポップアップを許可してください。');
        }
      } else {
        // PCの場合の処理
        const image = canvas.toDataURL('image/jpg').replace("image/jpg", "image/octet-stream");
        const link = document.createElement('a');
        link.href = image;
        link.download = 'selected-card-list.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  return (
    <div className="canvas-container">
      <button className="save-button" onClick={saveAsImage}>デッキを画像変換し保存</button>
      <canvas ref={canvasRef} className="deck-background" />
    </div>
  );
}


export default SelectedCards;
