export const announcements=[
{ 
    id: 23,
    title: "画像の追加", 
    content: "公開されたカード3種類[ギニュー,ベジータ,ビルス]を新たに追加しました",
    date: "2023-01-15 10:00" 
},
{ 
    id: 22,
    title: "画像の追加&機能追加", 
    content: "公開されたカード3種類[人造人間17号/人造人間18号,トランクス:未来,ヒット]を新たに追加しました  機能追加で複数検索詳細の追加　例（レアリティ（SRとR））同じ項目でも複数選べる用に修正",
    date: "2023-01-17 23:00" 
},
{ 
    id: 21,
    title: "画像の追加", 
    content: "公開されたカード8種類[メカフリーザ,ネイル,トランクス:未来,人造人間20号,クウラ機甲戦隊!!八ッ!!,人造人間18号,集結!第7宇宙代表,トランクス:未来]を新たに追加しました",
    date: "2023-01-15 10:00" 
},
{ 
    id: 20,
    title: "画像の追加", 
    content: "公開されたカード4種類[ザウザー,ベジータ,孫悟空,禍々しき分身]を新たに追加しました",
    date: "2023-01-13 1:00" 
},
{ 
    id: 19,
    title: "画像の追加", 
    content: "公開されたカード4種類[ピッコロ,第6宇宙の強者たち,人造人間18号,ファイナルホープスラッシュ]を新たに追加しました",
    date: "2023-01-11 20:30" 
},
{ 
    id: 18,
    title: "画像の追加", 
    content: "公開されたカード4種類[ネイズ,ゴクウブラック,人造人間17号,みんなそろってギニュー特戦隊!!!!]を新たに追加しました",
    date: "2023-01-11 10:00" 
},
{ 
    id: 17,
    title: "画像の追加", 
    content: "公開されたカード4種類[リクーム,ギニュー,人造人間16号,力の大会の武舞台]を新たに追加しました",
    date: "2023-01-10 10:00" 
},
{ 
    id: 16,
    title: "画像の追加", 
    content: "公開されたカード4種類[ギニュー特戦隊の絆,シン,ゴワス,ザマス]を新たに追加しました",
    date: "2023-01-08 21:00" 
},
{ 
    id: 15,
    title: "画像の追加", 
    content: "公開されたカード4種類[孫悟飯:少年期,クリリン,トランクス:未来,瞬間移動かめはめ波]を新たに追加しました",
    date: "2023-01-08 1:00" 
},
{ 
    id: 14,
    title: "画像の追加", 
    content: "公開されたカード2種類[ヤムチャ、ブラックかめはめ波]を新たに追加しました",
    date: "2023-01-07 15:00" 
},
{ 
    id: 13,
    title: "画像の追加", 
    content: "昨日から公開されたカード4種類[ベジータ、ウイス、ジース、ビッグ・バン・アタック]を新たに追加しました　その他バグ修正",
    date: "2023-01-06 15:00" 
},
{ 
    id: 12,
    title: "機能の追加", 
    content: "デッキ一覧機能をまとめました　サンプルデッキという形で掲載させていただいてます　今後優勝デッキ一覧など作っていけたらと思っております。その他バグ修正",
    date: "2023-01-04 12:00" 
},
{ 
    id: 11,
    title: "機能の追加", 
    content: "手札表示機能を付けました",
    date: "2023-01-03 12:00" 
},
{ 
    id: 10,
    title: "画像の追加&機能の追加", 
    content: "カードデータ追加「スーパーノヴァ(R)、孫悟空(R)、孫悟飯(R)」、選択したカードをURLで保持する機能を付けました",
    date: "2023-01-02 19:30" 
},
{ 
    id: 9,
    title: "画像の追加", 
    content: "カードデータ追加「サオネル,グルド,禍々しき大鎌」",
    date: "2023-12-31 3:00" 
},
{ 
    id: 8,
    title: "画像の追加", 
    content: "カードデータ追加「人造人間17号,マイ:未来,魔封波」",
    date: "2023-12-30 1:00" 
},
{ 
    id: 7,
    title: "機能の追加", 
    content: "検索機能追加、カードのレアリティ「L,PR,SR,R,UC,C」で検索できるように追加",
    date: "2023-12-29 12:00" 
},
{ 
    id: 6,
    title: "画像の追加", 
    content: "カードデータ追加「ブルマ、Dr.ゲロの研究所、バナン、FP（プロモプロモカード）」 バグ修正etc....",
    date: "2023-12-28 19:00" 
},
{ 
    id: 5,
    title: "画像の追加", 
    content: "カードデータ追加「ドーレ、フワ」 バグ修正etc....",
    date: "2023-12-28 5:00" 
},
{ 
    id: 4,
    title: "画像の追加", 
    content: "カードデータ追加「パンなど」 バグ修正",
    date: "2023-12-27 15:00" 
},
{ 
    id: 3,
    title: "画像の追加", 
    content: "カードデータ追加 「天津飯など」",
    date: "2023-12-26 22:00" 
},
{ 
    id: 2, 
    title: "新機能追加について", 
    content: "カード画像をクリックしたら拡大表示をする機能追加、またLEADERカードはスワイプで画像が切り替わる",
    date: "2023-12-25 10:00" 

},
{ 
    id: 1, 
    title: "非公式デッキ構築サイト:リリース", 
    content: "DBFWのデッキ構築サイトをリリースしました<br />サーバー負荷など色々と確認したい為リリースしてます<br />本番（2/16）に向けてこのまま少しずつアップデートを加えていく予定です",
    date: "2023-12-25 5:00" 

},
]