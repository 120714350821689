import React, { useState,useEffect } from 'react';
import Cards from './Cards';
import SelectedCards from './SelectedCards';
import { CardList as initialCardList } from '../CardsList/CardList';
import DeckRecipeName from './DeckRecipeName';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';
import { useLocation } from 'react-router-dom';
import DrawModalWithCanvas from './DrawModalWithCanvas';
import _ from 'lodash';
import {Card} from "../types/types"
import { Helmet } from 'react-helmet';

import { Switch } from 'antd'; // 例としてAnt DesignのSwitchコンポーネントを使用

interface DeckCard {
  cardId: string;
  count: number;
}



// Fisher-Yates シャッフルアルゴリズム
const shuffleDeck = (deck: Card[]): Card[] => {
  let flatDeck: Card[] = [];

  const filteredDeck = deck.filter(card => card.type !== "LEADER");

  // 各カードをその count の数だけ flatDeck に追加
  filteredDeck.forEach(card => {
    for (let i = 0; i < card.count; i++) {
      flatDeck.push({...card});
    }
  });

  // flatDeck をシャッフル
  for (let i = flatDeck.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [flatDeck[i], flatDeck[j]] = [flatDeck[j], flatDeck[i]];
  }

  return flatDeck;
};



function CardDeckBuilder() {
  const [cardList, setCardList] = useState(initialCardList);
  const [deckUrl, setDeckUrl] = useState('');
  const [hand, setHand] = useState<Card[]>([]); // `Card[]` 型を明示的に設定
  const location = useLocation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [filterActiveCards, setFilterActiveCards] = useState(false);
  const [resetPagination, setResetPagination] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false); // モーダル表示状態の管理

  const handleOpenConfirmModal = () => setShowConfirmModal(true);
  const handleCloseConfirmModal = () => setShowConfirmModal(false);

const handleConfirmReset = () => {
  resetAllCounts(); // すべてのカウントをリセットする関数を呼び出し
  handleCloseConfirmModal(); // モーダルを閉じる
};



  const onToggleChange = () => {
    setFilterActiveCards(!filterActiveCards);
    setResetPagination(!resetPagination);
  };

  const displayedCards = filterActiveCards
    ? cardList.filter(card => card.count > 0)
    : cardList;

  const getDeckState = (): DeckCard[] => {
    // 選択されたカード（count > 0）のみを含むリストを返す
    return cardList.filter(card => card.count > 0).map(card => {
      return { cardId: card.cardId, count: card.count };
    });
  };
  
  const setDeckState = (deckState:DeckCard[]) => {
    // 新しいデッキ状態に基づいてcardListを更新
    const updatedList = [...initialCardList]; // 元のリストをコピー
    deckState.forEach(deckCard => {
      const index = updatedList.findIndex(card => card.cardId === deckCard.cardId);
      if (index !== -1) {
        updatedList[index].count = deckCard.count;
      }
    });
  
    setCardList(updatedList); // 更新されたリストでcardListステートを設定
  };

  const saveDeckToUrl = () => {
    // デッキの状態を取得（例：選択されたカードのIDリスト）
    const deckState = getDeckState();
    
    // デッキ状態を文字列に変換してBase64エンコード
    const encodedState = btoa(JSON.stringify(deckState));
    const newUrl = `https://www.dbfw-deckbuilder.com/tool/deck-builder/?deck=${encodedState}`;
    setDeckUrl(newUrl); // 新しいURLをdeckUrlステートに保存
  };

  // テキストボックスに表示されているURLをクリップボードにコピーする関数
  const copyToClipboard = () => {
    navigator.clipboard.writeText(deckUrl).then(() => {
      alert('コピーしました！');
    }, (err) => {
      console.error('非同期クリップボードの書き込みに失敗しました: ', err);
    });
  };

  // モーダルを開く関数
  const openModal = () => {
    // 任意の追加処理（例：デッキのシャッフル）
    const shuffledDeck = shuffleDeck(_.cloneDeep(cardList));
    setHand(shuffledDeck.slice(0, 6)); // シャッフルされたデッキから6枚を選択
    setModalOpen(true);
  };

  // モーダルを閉じる関数
  const closeModal = () => {
    setModalOpen(false);
  };

  

  useEffect(() => {
    // URLからクエリパラメータを取得
    const queryParams = new URLSearchParams(location.search);
    const deckStateEncoded = queryParams.get('deck');

    if (deckStateEncoded) {
      // Base64デコードしてデッキ状態を取得
      const deckState = JSON.parse(atob(deckStateEncoded));
      
      // デッキ状態を復元
      setDeckState(deckState);
    }
  }, [location]);

// フォームデータを状態として管理
const [formData, setFormData] = useState({
  tournamentName: '',
  deckName: '',
  author: '',
  account: '',
  comment: '',
});

const setCountToFour = (cardId: string) => {
  const updatedList = [...cardList];
  const index = updatedList.findIndex(card => card.cardId === cardId);
  if (index !== -1) {
    updatedList[index].count = 4; // カウントを4に設定
    
    setCardList(updatedList); // 更新されたリストでCardListステートを設定
  }
};

const resetAllCounts = () => {
  const updatedList = cardList.map(card => {
    return { ...card, count: 0 };
  });
  setCardList(updatedList); // 更新されたリストでcardListステートを設定
};

const resetCount = (cardId: string) => {
  const updatedList = [...cardList];
  const index = updatedList.findIndex(card => card.cardId === cardId);
  if (index !== -1) {
    updatedList[index].count = 0; // カウントを0にリセット
    setCardList(updatedList); // 更新されたリストでCardListステートを設定
  }
};

const increment = (cardId: string) => {
  const updatedList = [...cardList];
  const index = updatedList.findIndex(card => card.cardId === cardId);

  if (index !== -1) {
    const card = updatedList[index];

    if (card.type === "LEADER") {
      // LEADERタイプのカードの場合、他のLEADERカードのカウントを0にリセット
      updatedList.forEach(card => {
        if (card.type === "LEADER" && card.cardId !== cardId) {
          card.count = 0;
        }
      });

      // 新しいLEADERカードのカウントを1に設定（既に1の場合は変更しない）
      if (card.count === 0) {
        card.count = 1;
      }
    } else {
      // infiniteがtrueの場合はカウントの上限なし
      if (card.infinite) {
        card.count += 1;
      } else if (card.count < 4) {
        // 通常のカード（infiniteがfalse）の場合、カウントを増やす（上限は4）
        card.count += 1;
      }
    }
  }

  setCardList(updatedList);
};

const decrement = (cardId: string) => {
  const updatedList = [...cardList];
  const index = updatedList.findIndex(card => card.cardId === cardId);

  if (index !== -1 && updatedList[index].count > 0) {
    if (updatedList[index].type !== "LEADER") {
      updatedList[index].count -= 1;
    } else if (updatedList[index].type === "LEADER" && updatedList[index].count === 1) {
      updatedList[index].count -= 1;
    }
    setCardList(updatedList);
  }
};

// フォームデータが変更されたときに呼ばれる関数
const handleFormDataChange = (newFormData: {
  tournamentName: string;
  deckName: string;
  author: string;
  account: string;
  comment: string;
}) => {
  setFormData(newFormData);
};


  return (
    <div className="content">
      <Helmet>
      <title>デッキ構築 - ドラゴンボール フュージョンワールド デッキ 構築 ガイド</title>
      <meta name="description" content="デッキ構築を提供するページです。"/>
      {/* ここに追加で構造化データなどのマークアップを挿入できます */}
      </Helmet>

      <h1 >デッキ構築</h1>
      任意でデッキ名や作者名を入力できます。追加した情報やカードはプレビューにリアルタイムで反映されます。
      <div className="card mb-3">
      <div className="card-header text-start dark-card-header">
        1. デッキレシピ名
      </div>
    </div>
    <div>

    </div>
    <DeckRecipeName onFormDataChange={handleFormDataChange}/>
    <div className="form-group">
    <input
        type="text"
        value={deckUrl}
        onClick={copyToClipboard}
        readOnly
      />
      <button onClick={saveDeckToUrl}>デッキをURLに保存</button></div>
    <SelectedCards CardList={cardList} formData={formData} />
    <div className="form-group">
     {/* UI コンポーネントとボタン */}
     <button onClick={openModal}>手札を表示</button>
    {/* モーダルウィンドウ */}
     <DrawModalWithCanvas isOpen={isModalOpen} onClose={closeModal} hand={hand} />
    </div>
    <div className="card mb-3">
      <div className="card-header text-start dark-card-header">
        2. カード検索
      </div>
    </div>
    <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          margin: '24px 0' // 上下に16ピクセルのマージン
        }}>
      <span>カード検索</span>
      <Switch checked={filterActiveCards} onChange={onToggleChange} style={{ margin: '0 8px' }} />
      <span>デッキ内表示</span>
    </div>

    {/* 確認モーダル */}
{showConfirmModal && (
  <div className="modal show" role="dialog" style={{ display: 'block' }}>
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">確認</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseConfirmModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>本当にレシピを空にしますか？</p>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={handleCloseConfirmModal}>いいえ</button>
          <button type="button" className="btn btn-primary" onClick={handleConfirmReset}>はい</button>
        </div>
      </div>
    </div>
  </div>
)}
    <button style={{ 
          margin: '0 0 24px' // 上下に16ピクセルのマージン
    }} onClick={handleOpenConfirmModal}>レシピを空にする</button>
    <Cards CardList={displayedCards} increment={increment} decrement={decrement} countToFour={setCountToFour } resetCount={resetCount } resetPagination={resetPagination} />
   
    </div>
  );
}

export default CardDeckBuilder;
