export const FB03_LEADER_CardList=[
    {
        cardId:"FB03-001",
        name:"ジレン",
        color:"赤",
        rarity:"L",
        cost:"L",
        image:["/image/FB03/FB03-001.webp","/image/FB03/FB03-001-2.webp"],
        count:0,
        type:"LEADER",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-078",
        name:"バビディ",
        color:"黄",
        rarity:"L",
        cost:"L",
        image:["/image/FB03/FB03-078.webp","/image/FB03/FB03-078-2.webp"],
        count:0,
        type:"LEADER",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-053",
        name:"ピッコロ",
        color:"緑",
        rarity:"L",
        cost:"L",
        image:["/image/FB03/FB03-053.webp","/image/FB03/FB03-053-2.webp"],
        count:0,
        type:"LEADER",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-027",
        name:"孫悟空",
        color:"青",
        rarity:"L",
        cost:"L",
        image:["/image/FB03/FB03-027.webp","/image/FB03/FB03-027-2.webp"],
        count:0,
        type:"LEADER",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-104",
        name:"孫悟空:GT",
        color:"黒",
        rarity:"L",
        cost:"L",
        image:["/image/FB03/FB03-104.webp","/image/FB03/FB03-104-2.webp"],
        count:0,
        type:"LEADER",
        pack:"FB03",
        infinite:false
    },
]