export const FB02_LEADER_CardList=[
    {
        cardId:"FB02-001",
        name:"孫悟空",
        color:"赤",
        rarity:"L",
        cost:"L",
        image:["/image/FB02/FB02-001.webp","/image/FB02/FB02-001-2.webp"],
        count:0,
        type:"LEADER",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-105",
        name:"ベジータ",
        color:"黄",
        rarity:"L",
        cost:"L",
        image:["/image/FB02/FB02-105.webp","/image/FB02/FB02-105-2.webp"],
        count:0,
        type:"LEADER",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-036",
        name:"ザマス:合体",
        color:"青",
        rarity:"L",
        cost:"L",
        image:["/image/FB02/FB02-036.webp","/image/FB02/FB02-036-2.webp"],
        count:0,
        type:"LEADER",
        pack:"FB02",
        infinite:false
    },
    {
        cardId:"FB02-070",
        name:"セル",
        color:"緑",
        rarity:"L",
        cost:"L",
        image:["/image/FB02/FB02-070.webp","/image/FB02/FB02-070-2.webp"],
        count:0,
        type:"LEADER",
        pack:"FB02",
        infinite:false
    },
]