export const FS03_CardList=[

    
{
    cardId:"FS03-16",
    name:"破壊の剛腕",
    color:"緑",
    rarity:"C",
    cost:"3",
    image:["/image/FS/FS03/FS03-16.webp"],
    count:0,
    type:"EXTRA",
    pack:"FS03",
    infinite:false
},
{
    cardId:"FS03-15",
    name:"ギガンティックミーティア",
    color:"緑",
    rarity:"C",
    cost:"1",
    image:["/image/FS/FS03/FS03-15.webp"],
    count:0,
    type:"EXTRA",
    pack:"FS03",
    infinite:false
},
{
    cardId:"FS03-06",
    name:"パラガス",
    color:"緑",
    rarity:"C",
    cost:"2",
    image:["/image/FS/FS03/FS03-06.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS03",
    infinite:false
},
{
    cardId:"FS03-03",
    name:"シャモ星人",
    color:"緑",
    rarity:"C",
    cost:"1",
    image:["/image/FS/FS03/FS03-03.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS03",
    infinite:false
},
{
    cardId:"FS03-04",
    name:"ターレス",
    color:"緑",
    rarity:"SR",
    cost:"5",
    image:["/image/FS/FS03/FS03-04.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS03",
    infinite:false
},
{
    cardId:"FS03-10",
    name:"ブロリー:BR",
    color:"緑",
    rarity:"SR",
    cost:"8",
    image:["/image/FS/FS03/FS03-10.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS03",
    infinite:false
},
{
    cardId:"FS03-12",
    name:"ベジータ王",
    color:"緑",
    rarity:"C",
    cost:"7",
    image:["/image/FS/FS03/FS03-12.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS03",
    infinite:false
},
{
    cardId:"FS03-02",
    name:"アンゴル",
    color:"緑",
    rarity:"C",
    cost:"2",
    image:["/image/FS/FS03/FS03-02.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS03",
    infinite:false
},
{
    cardId:"FS03-07",
    name:"パラガス",
    color:"緑",
    rarity:"C",
    cost:"2",
    image:["/image/FS/FS03/FS03-07.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS03",
    infinite:false
},
{
    cardId:"FS03-11",
    name:"ベジータ",
    color:"緑",
    rarity:"C",
    cost:"3",
    image:["/image/FS/FS03/FS03-11.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS03",
    infinite:false
},
{
    cardId:"FS03-13",
    name:"モア",
    color:"緑",
    rarity:"C",
    cost:"1",
    image:["/image/FS/FS03/FS03-13.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS03",
    infinite:false
},
{
    cardId:"FS03-05",
    name:"ナッパ",
    color:"緑",
    rarity:"C",
    cost:"2",
    image:["/image/FS/FS03/FS03-05.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS03",
    infinite:false
},
{
    cardId:"FS03-08",
    name:"ブロリー",
    color:"緑",
    rarity:"C",
    cost:"4",
    image:["/image/FS/FS03/FS03-08.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS03",
    infinite:false
},
{
    cardId:"FS03-09",
    name:"ブロリー",
    color:"緑",
    rarity:"C",
    cost:"6",
    image:["/image/FS/FS03/FS03-09.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS03",
    infinite:false
},
{
    cardId:"FS03-14",
    name:"ラディッツ",
    color:"緑",
    rarity:"C",
    cost:"1",
    image:["/image/FS/FS03/FS03-14.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS03",
    infinite:false
},
{
    cardId:"FS03-01",
    name:"ブロリー",
    color:"緑",
    rarity:"L",
    cost:"L",
    image:["/image/FS/FS03/FS03-01.webp","/image/FS/FS03/FS03-01-2.webp"],
    count:0,
    type:"LEADER",
    pack:"FS03",
    infinite:false
},
]