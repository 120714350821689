export const FB03_BATTLE_CardList=[
    {
        cardId:"FB03-020",
        name:"ベジータ",
        color:"赤",
        rarity:"SR",
        cost:"3",
        image:["/image/FB03/FB03-020.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-008",
        name:"ココット",
        color:"赤",
        rarity:"UC",
        cost:"7",
        image:["/image/FB03/FB03-008.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-093",
        name:"魔人ブウ",
        color:"黄",
        rarity:"SR",
        cost:"3",
        image:["/image/FB03/FB03-093.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-085",
        name:"ビビディ",
        color:"黄",
        rarity:"UC",
        cost:"7",
        image:["/image/FB03/FB03-085.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-064",
        name:"孫悟空",
        color:"緑",
        rarity:"SR",
        cost:"6",
        image:["/image/FB03/FB03-064.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-066",
        name:"孫悟飯:幼年期",
        color:"緑",
        rarity:"UC",
        cost:"7",
        image:["/image/FB03/FB03-066.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-039",
        name:"孫悟飯:青年期",
        color:"青",
        rarity:"SR",
        cost:"4",
        image:["/image/FB03/FB03-039.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-035",
        name:"人造人間18号",
        color:"青",
        rarity:"UC",
        cost:"7",
        image:["/image/FB03/FB03-035.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-118",
        name:"トランクス:GT",
        color:"黒",
        rarity:"UC",
        cost:"2",
        image:["/image/FB03/FB03-118.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-127",
        name:"ブラ:GT",
        color:"黒",
        rarity:"UC",
        cost:"2",
        image:["/image/FB03/FB03-127.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-107",
        name:"ギネ:BR",
        color:"黒",
        rarity:"UC",
        cost:"1",
        image:["/image/FB03/FB03-107.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-123",
        name:"パラパラブラザーズ",
        color:"黒",
        rarity:"UC",
        cost:"1",
        image:["/image/FB03/FB03-123.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-108",
        name:"ギル",
        color:"黒",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-108.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-111",
        name:"孫悟空:GT",
        color:"黒",
        rarity:"SR",
        cost:"3",
        image:["/image/FB03/FB03-111.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-105",
        name:"ウーブ",
        color:"黒",
        rarity:"R",
        cost:"2",
        image:["/image/FB03/FB03-105.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-028",
        name:"イレーザ",
        color:"青",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-028.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-033",
        name:"シャプナー",
        color:"青",
        rarity:"UC",
        cost:"1",
        image:["/image/FB03/FB03-033.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-054",
        name:"閻魔大王",
        color:"緑",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-054.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-079",
        name:"スポポビッチ",
        color:"黄",
        rarity:"C",
        cost:"2",
        image:["/image/FB03/FB03-079.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-081",
        name:"ダーブラ",
        color:"黄",
        rarity:"UC",
        cost:"2",
        image:["/image/FB03/FB03-081.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-014",
        name:"ディスポ",
        color:"赤",
        rarity:"R",
        cost:"2",
        image:["/image/FB03/FB03-014.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-058",
        name:"グレゴリー",
        color:"緑",
        rarity:"UC",
        cost:"2",
        image:["/image/FB03/FB03-058.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-016",
        name:"トッポ",
        color:"赤",
        rarity:"SR",
        cost:"4",
        image:["/image/FB03/FB03-016.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-121",
        name:"バーダック",
        color:"黒",
        rarity:"SR",
        cost:"3",
        image:["/image/FB03/FB03-121.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-048",
        name:"マイティマスク",
        color:"青",
        rarity:"UC",
        cost:"2",
        image:["/image/FB03/FB03-048.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-068",
        name:"バブルス",
        color:"緑",
        rarity:"UC",
        cost:"1",
        image:["/image/FB03/FB03-068.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-086",
        name:"プイプイ",
        color:"黄",
        rarity:"R",
        cost:"2",
        image:["/image/FB03/FB03-086.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-114",
        name:"孫悟飯:GT",
        color:"黒",
        rarity:"R",
        cost:"3",
        image:["/image/FB03/FB03-114.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-042",
        name:"ビーデル",
        color:"青",
        rarity:"SR",
        cost:"2",
        image:["/image/FB03/FB03-042.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-021",
        name:"ベルモッド",
        color:"赤",
        rarity:"UC",
        cost:"2",
        image:["/image/FB03/FB03-021.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-089",
        name:"ベジータ",
        color:"黄",
        rarity:"SR",
        cost:"4",
        image:["/image/FB03/FB03-089.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-009",
        name:"ジレン",
        color:"赤",
        rarity:"SR",
        cost:"4",
        image:["/image/FB03/FB03-009.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-125",
        name:"パン:GT",
        color:"黒",
        rarity:"SR",
        cost:"2",
        image:["/image/FB03/FB03-125.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-139",
        name:"孫悟飯:幼年期",
        color:"緑",
        rarity:"CSR",
        cost:"2",
        image:["/image/FB03/FB03-139.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-140",
        name:"孫悟空:GT",
        color:"黒",
        rarity:"CSR",
        cost:"4",
        image:["/image/FB03/FB03-140.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-002",
        name:"カーセラル",
        color:"赤",
        rarity:"R",
        cost:"3",
        image:["/image/FB03/FB03-002.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-003",
        name:"カイ",
        color:"赤",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-003.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-004",
        name:"キャベ",
        color:"赤",
        rarity:"R",
        cost:"3",
        image:["/image/FB03/FB03-004.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-005",
        name:"クンシー",
        color:"赤",
        rarity:"UC",
        cost:"2",
        image:["/image/FB03/FB03-005.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-006",
        name:"ケットル",
        color:"赤",
        rarity:"C",
        cost:"2",
        image:["/image/FB03/FB03-006.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-007",
        name:"ケフラ",
        color:"赤",
        rarity:"C",
        cost:"3",
        image:["/image/FB03/FB03-007.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-010",
        name:"ゾイレー",
        color:"赤",
        rarity:"UC",
        cost:"1",
        image:["/image/FB03/FB03-010.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-011",
        name:"孫悟空",
        color:"赤",
        rarity:"UC",
        cost:"3",
        image:["/image/FB03/FB03-011.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-012",
        name:"タッパー",
        color:"赤",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-012.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-013",
        name:"ディスポ",
        color:"赤",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-013.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-015",
        name:"トッポ",
        color:"赤",
        rarity:"C",
        cost:"3",
        image:["/image/FB03/FB03-015.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-017",
        name:"ヒット",
        color:"赤",
        rarity:"UC",
        cost:"2",
        image:["/image/FB03/FB03-017.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-018",
        name:"ビルス",
        color:"赤",
        rarity:"R",
        cost:"3",
        image:["/image/FB03/FB03-018.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-019",
        name:"ブーオン",
        color:"赤",
        rarity:"uC",
        cost:"2",
        image:["/image/FB03/FB03-019.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-029",
        name:"ウイス",
        color:"青",
        rarity:"UC",
        cost:"1",
        image:["/image/FB03/FB03-029.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-030",
        name:"キビト",
        color:"青",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-030.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-031",
        name:"クリリン",
        color:"青",
        rarity:"C",
        cost:"2",
        image:["/image/FB03/FB03-031.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-032",
        name:"グレートサイヤマン",
        color:"青",
        rarity:"C",
        cost:"4",
        image:["/image/FB03/FB03-032.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },    
    {
        cardId:"FB03-034",
        name:"シン",
        color:"青",
        rarity:"C",
        cost:"2",
        image:["/image/FB03/FB03-034.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-036",
        name:"孫悟空",
        color:"青",
        rarity:"UC",
        cost:"2",
        image:["/image/FB03/FB03-036.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-037",
        name:"孫悟天",
        color:"青",
        rarity:"R",
        cost:"2",
        image:["/image/FB03/FB03-037.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-038",
        name:"孫悟飯：青年期",
        color:"青",
        rarity:"C",
        cost:"3",
        image:["/image/FB03/FB03-038.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-040",
        name:"トランクス：未来",
        color:"青",
        rarity:"R",
        cost:"2",
        image:["/image/FB03/FB03-040.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },    
    {
        cardId:"FB03-041",
        name:"トランクス：幼年期",
        color:"青",
        rarity:"C",
        cost:"2",
        image:["/image/FB03/FB03-041.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-043",
        name:"ビーデル",
        color:"青",
        rarity:"C",
        cost:"2",
        image:["/image/FB03/FB03-043.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-044",
        name:"ピッコロ",
        color:"青",
        rarity:"C",
        cost:"3",
        image:["/image/FB03/FB03-044.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-045",
        name:"ビルス",
        color:"青",
        rarity:"R",
        cost:"2",
        image:["/image/FB03/FB03-045.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-046",
        name:"ブルマ",
        color:"青",
        rarity:"UC",
        cost:"1",
        image:["/image/FB03/FB03-046.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-047",
        name:"ベジータ",
        color:"青",
        rarity:"R",
        cost:"2",
        image:["/image/FB03/FB03-047.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-049",
        name:"ミスター・サタン",
        color:"青",
        rarity:"R",
        cost:"1",
        image:["/image/FB03/FB03-049.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-055",
        name:"神様",
        color:"緑",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-055.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-056",
        name:"亀仙人",
        color:"緑",
        rarity:"UC",
        cost:"1",
        image:["/image/FB03/FB03-056.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-057",
        name:"北の界王",
        color:"緑",
        rarity:"R",
        cost:"3",
        image:["/image/FB03/FB03-057.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-059",
        name:"人造人間17号",
        color:"緑",
        rarity:"R",
        cost:"3",
        image:["/image/FB03/FB03-059.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-060",
        name:"セル",
        color:"緑",
        rarity:"UC",
        cost:"5",
        image:["/image/FB03/FB03-060.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-061",
        name:"セル",
        color:"緑",
        rarity:"R",
        cost:"8",
        image:["/image/FB03/FB03-061.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-062",
        name:"戦闘力5のおじさん",
        color:"緑",
        rarity:"C",
        cost:"0",
        image:["/image/FB03/FB03-062.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-063",
        name:"孫悟空",
        color:"緑",
        rarity:"C",
        cost:"2",
        image:["/image/FB03/FB03-063.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-065",
        name:"孫悟飯：幼年期",
        color:"緑",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-065.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-067",
        name:"ナッパ",
        color:"緑",
        rarity:"C",
        cost:"6",
        image:["/image/FB03/FB03-067.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-069",
        name:"ピッコロ",
        color:"緑",
        rarity:"C",
        cost:"3",
        image:["/image/FB03/FB03-069.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-071",
        name:"ブルマ",
        color:"緑",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-071.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-072",
        name:"ベジータ",
        color:"緑",
        rarity:"C",
        cost:"7",
        image:["/image/FB03/FB03-072.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-073",
        name:"ラディッツ",
        color:"緑",
        rarity:"UC",
        cost:"3",
        image:["/image/FB03/FB03-073.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-074",
        name:"ラディッツ",
        color:"緑",
        rarity:"R",
        cost:"6",
        image:["/image/FB03/FB03-074.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-080",
        name:"孫悟空",
        color:"黄",
        rarity:"R",
        cost:"3",
        image:["/image/FB03/FB03-080.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-082",
        name:"ダーブラ",
        color:"黄",
        rarity:"UC",
        cost:"3",
        image:["/image/FB03/FB03-082.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-083",
        name:"バビディ",
        color:"黄",
        rarity:"R",
        cost:"2",
        image:["/image/FB03/FB03-083.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-084",
        name:"バビディ",
        color:"黄",
        rarity:"C",
        cost:"2",
        image:["/image/FB03/FB03-084.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-087",
        name:"ブルマ",
        color:"黄",
        rarity:"UC",
        cost:"1",
        image:["/image/FB03/FB03-087.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-088",
        name:"ベジータ",
        color:"黄",
        rarity:"C",
        cost:"2",
        image:["/image/FB03/FB03-088.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-090",
        name:"ベジータ",
        color:"黄",
        rarity:"C",
        cost:"3",
        image:["/image/FB03/FB03-090.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-091",
        name:"魔人ブウ：純粋悪",
        color:"黄",
        rarity:"UC",
        cost:"3",
        image:["/image/FB03/FB03-091.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-092",
        name:"魔人ブウ",
        color:"黄",
        rarity:"C",
        cost:"4",
        image:["/image/FB03/FB03-092.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-094",
        name:"メタルクウラ・コア",
        color:"黄",
        rarity:"R",
        cost:"4",
        image:["/image/FB03/FB03-094.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-095",
        name:"ヤコン",
        color:"黄",
        rarity:"UC",
        cost:"2",
        image:["/image/FB03/FB03-095.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-096",
        name:"ヤムー",
        color:"黄",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-096.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-097",
        name:"リクーム",
        color:"黄",
        rarity:"UC",
        cost:"2",
        image:["/image/FB03/FB03-097.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-106",
        name:"黄金大猿孫悟空",
        color:"黒",
        rarity:"C",
        cost:"3",
        image:["/image/FB03/FB03-106.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-109",
        name:"ズーナマー",
        color:"黒",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-109.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-110",
        name:"孫悟空：GT",
        color:"黒",
        rarity:"R",
        cost:"1",
        image:["/image/FB03/FB03-110.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-112",
        name:"孫悟空：少年期BR",
        color:"黒",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-112.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-113",
        name:"孫悟天：GT",
        color:"黒",
        rarity:"UC",
        cost:"2",
        image:["/image/FB03/FB03-113.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-115",
        name:"チルド",
        color:"黒",
        rarity:"UC",
        cost:"3",
        image:["/image/FB03/FB03-115.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-116",
        name:"トオロ",
        color:"黒",
        rarity:"UC",
        cost:"2",
        image:["/image/FB03/FB03-116.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-117",
        name:"Dr.ミュー",
        color:"黒",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-117.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-119",
        name:"トランクス：GT",
        color:"黒",
        rarity:"R",
        cost:"3",
        image:["/image/FB03/FB03-119.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-120",
        name:"ドルタッキー",
        color:"黒",
        rarity:"UC",
        cost:"3",
        image:["/image/FB03/FB03-120.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-122",
        name:"バーダック：BR",
        color:"黒",
        rarity:"UC",
        cost:"2",
        image:["/image/FB03/FB03-122.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-124",
        name:"パン：GT",
        color:"黒",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-124.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-126",
        name:"ピッコロ：GT",
        color:"黒",
        rarity:"C",
        cost:"2",
        image:["/image/FB03/FB03-126.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-128",
        name:"ブルマ：GT",
        color:"黒",
        rarity:"R",
        cost:"1",
        image:["/image/FB03/FB03-128.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-129",
        name:"ベジータ：GT",
        color:"黒",
        rarity:"C",
        cost:"3",
        image:["/image/FB03/FB03-129.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-130",
        name:"ベジータ：少年期BR",
        color:"黒",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-130.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-131",
        name:"魔人ブウ：GT",
        color:"黒",
        rarity:"C",
        cost:"1",
        image:["/image/FB03/FB03-131.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-132",
        name:"ミスター・サタン：GT",
        color:"黒",
        rarity:"R",
        cost:"1",
        image:["/image/FB03/FB03-132.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    },
    {
        cardId:"FB03-133",
        name:"ルード",
        color:"黒",
        rarity:"C",
        cost:"4",
        image:["/image/FB03/FB03-133.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
    {
        cardId:"FB03-134",
        name:"レジック",
        color:"黒",
        rarity:"R",
        cost:"2",
        image:["/image/FB03/FB03-134.webp"],
        count:0,
        type:"BATTLE",
        pack:"FB03",
        infinite:false
    
    },
]