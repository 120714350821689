export const FS01_CardList=[
    
{
    cardId:"FS01-09",
    name:"孫悟飯:青年期",
    color:"赤",
    rarity:"SR",
    cost:"4",
    image:["/image/FS/FS01/FS01-09.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS01",
    infinite:false
    
},
{
    cardId:"FS01-15",
    name:"かめはめ波",
    color:"赤",
    rarity:"C",
    cost:"1",
    image:["/image/FS/FS01/FS01-15.webp"],
    count:0,
    type:"EXTRA",
    pack:"FS01",
    infinite:false
},

{
    cardId:"FS01-07",
    name:"人造人間18号",
    color:"赤",
    rarity:"C",
    cost:"2",
    image:["/image/FS/FS01/FS01-07.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS01",
    infinite:false
},
{
    cardId:"FS01-08",
    name:"孫悟空",
    color:"赤",
    rarity:"SR",
    cost:"5",
    image:["/image/FS/FS01/FS01-08.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS01",
    infinite:false
},

{
    cardId:"FS01-03",
    name:"亀仙人",
    color:"赤",
    rarity:"C",
    cost:"1",
    image:["/image/FS/FS01/FS01-03.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS01",
    infinite:false
},
{
    cardId:"FS01-02",
    name:"ウイス",
    color:"赤",
    rarity:"C",
    cost:"1",
    image:["/image/FS/FS01/FS01-02.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS01",
    infinite:false
},
{
    cardId:"FS01-12",
    name:"ビルス",
    color:"赤",
    rarity:"C",
    cost:"3",
    image:["/image/FS/FS01/FS01-12.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS01",
    infinite:false
},
{
    cardId:"FS01-16",
    name:"ゴッドかめはめ波",
    color:"赤",
    rarity:"C",
    cost:"2",
    image:["/image/FS/FS01/FS01-16.webp"],
    count:0,
    type:"EXTRA",
    pack:"FS01",
    infinite:false
},
{
    cardId:"FS01-05",
    name:"シン",
    color:"赤",
    rarity:"C",
    cost:"7",
    image:["/image/FS/FS01/FS01-05.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS01",
    infinite:false
},
{
    cardId:"FS01-11",
    name:"ピッコロ",
    color:"赤",
    rarity:"C",
    cost:"3",
    image:["/image/FS/FS01/FS01-11.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS01",
    infinite:false
},
{
    cardId:"FS01-06",
    name:"人造人間17号",
    color:"赤",
    rarity:"C",
    cost:"3",
    image:["/image/FS/FS01/FS01-06.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS01",
    infinite:false
},
{
    cardId:"FS01-04",
    name:"クリリン",
    color:"赤",
    rarity:"C",
    cost:"2",
    image:["/image/FS/FS01/FS01-04.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS01",
    infinite:false
},

{
    cardId:"FS01-14",
    name:"ベジータ",
    color:"赤",
    rarity:"C",
    cost:"2",
    image:["/image/FS/FS01/FS01-14.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS01",
    infinite:false
},
{
    cardId:"FS01-10",
    name:"天津飯",
    color:"赤",
    rarity:"C",
    cost:"1",
    image:["/image/FS/FS01/FS01-10.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS01",
    infinite:false
},
{
    cardId:"FS01-13",
    name:"フリーザ",
    color:"赤",
    rarity:"C",
    cost:"4",
    image:["/image/FS/FS01/FS01-13.webp"],
    count:0,
    type:"BATTLE",
    pack:"FS01",
    infinite:false
},
{
    cardId:"FS01-01",
    name:"孫悟空",
    color:"赤",
    rarity:"L",
    cost:"L",
    image:["/image/FS/FS01/FS01-01.webp","/image/FS/FS01/FS01-01-2.webp"],
    count:0,
    type:"LEADER",
    pack:"FS01",
    infinite:false
},
]