import React from 'react';
import "./CardStyle.css"
import {Card} from "../types/types"

// モーダルとカンバスのプロパティの型を定義
interface ModalWithCanvasProps {
    isOpen: boolean;
    onClose: () => void;
    hand: Card[];
  }
  
  const DrawModalWithCanvas: React.FC<ModalWithCanvasProps> = ({ isOpen, onClose, hand }) => {
  return (
    <div className={`modal ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none', maxWidth: '100%' ,margin: 'auto' }}>
      <div className="modal-dialog modal-dialog-centered" style={{ minWidth: '50%', margin: '0 auto' }}>
        <div className="modal-content" style={{ minWidth: '50%' }}>
          <div className="modal-body">
            手札
            <div  style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center' }}>
              {hand.map((card, index) => (
                <img key={index} src={card.image[0]} style={{ width: '15%', height: '20%', margin: '5px' }} alt={`Card ${index}`} />
              ))}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>閉じる</button>
          </div>
        </div>
      </div>
    </div>
  );
};
  
  export default DrawModalWithCanvas;