import React, { useState, useEffect } from 'react';
import '../index.css';
import './PageStyle.css';
import { Link } from 'react-router-dom';
import {EventCup} from '../types/types'
import eventCup from '../js/eventCup.json'; // JSON データのインポート

function EventNavigation  () {
  const [eventCups, setEventCup] = useState<EventCup[]>(eventCup as EventCup[]);
  useEffect(() => {
    // 最初の3つのデッキデータだけをセットする
    setEventCup(eventCup.slice(0, 1));

}, []);
  return(
    <div className="EventNavigation">
        <div className="card mb-4">
            <h1>(非公認CS)開催決定</h1>
            </div>
            <div className="home-deck-detail-list">
                {eventCups.map(e => (
                <Link to={e.url} className="home-deck-detail" key={e.eventName} style={{ textDecoration: 'none' }}>
                        <h2>{e.eventName}</h2>
                        <h2>{e.day}</h2>
                        <img src={e.image} alt={e.eventName} />
                        <h2>{e.entry}</h2>
                </Link>
                ))}
            </div>
        
    </div>
  );
}

  export default EventNavigation;