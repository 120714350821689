import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import decksData from '../js/decks.json'; // 正しいパスを設定してください
import './ListStyle.css'
import { CardList } from '../CardsList/CardList';
import { Card, Deck } from '../types/types';
import { Helmet } from 'react-helmet';

import Slider from 'react-slick'; // react-slick をインポート

const DeckDetail = () => {
    const { deckName } = useParams<{ deckName: string | undefined }>();
    const deck:Deck|undefined = decksData.find(d => d.deckName === decodeURIComponent(deckName as string));
    const [showDeckList, setShowDeckList] = useState(false); // リスト表示状態を管理
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImages, setSelectedImages] = useState<string[]>([]);
    const handleImageClick = (images: string[]) => {
        setSelectedImages(images); // 複数の画像を設定
        setIsModalOpen(true);
      };

      const ImageModal = () => {
        const settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true
        };
      const closeModal = () => {
        setIsModalOpen(false);
      };
      return (
        <div className={`modal ${isModalOpen ? 'show' : ''}`} tabIndex={-1} style={{ display: isModalOpen ? 'block' : 'none' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <Slider {...settings}>
                  {selectedImages.map((image, index) => (
                    <div key={index}>
                      <img src={image} className="img-fluid" alt={`Card number ${index + 1}`} />
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>閉じる</button>
              </div>
            </div>
          </div>
        </div>
      );
    };
    // CardList を cardId をキーとするオブジェクトに変換
    const cardDataMap : Record<string, Card> = CardList.reduce((map: Record<string, Card>, card: Card) => {
        map[card.cardId] = card;
        return map;
    }, {});

    // リーダーカードの詳細情報を取得
    const leaderCard = deck && deck.cards.length > 0 ? cardDataMap[deck.cards[0].cardId] : null;
    const calculateTotalQuantity = () => {
        if (!deck) return 0;
    
        return deck.cards.reduce((acc, card) => {
            const cardInfo = cardDataMap[card.cardId];
            // リーダーカードを除外して枚数を加算
            if (cardInfo && cardInfo.type !== 'LEADER') {
                return acc + card.quantity;
            }
            return acc;
        }, 0);
    };
    
    // 合計枚数の計算
    const totalQuantity = calculateTotalQuantity();
    return (
        <div className="deck-detail-container-2">
            <Helmet>
                <title>デッキ - ドラゴンボール フュージョンワールド デッキ 構築 ガイド</title>
                <meta name="description" content="デッキを提供するページです。"/>
                {/* ここに追加で構造化データなどのマークアップを挿入できます */}
            </Helmet>
            {deck ? (
                <div>
                    <div className="card mb-4">
                    <h1>{deck.deckName} デッキタイプまとめ</h1>
                    </div>
                    <p>【ドラゴンボールフュージョンワールド】 Twitterで見つけた【{deck.deckName}】デッキについて紹介します！
                        <br/>「サンプルデッキレシピ」を掲載しているので参考にしてください！</p>
                    <div className="card mb-4">
                    <h2>リーダーカード</h2>
                    </div>
                    {leaderCard && 
                    <img style={{width: "20%",height:"20%",marginBottom:"1%"}} src={leaderCard.image[0]} alt={leaderCard.name} onClick={() => handleImageClick(leaderCard.image)}/>
                    }
                    <div className="card mb-4">
                    <h2>デッキ説明</h2>
                    </div>
                    <p>{deck.strategy}</p>
                    <div className="card mb-4">
                    <h2>デッキレシピ 合計{totalQuantity}枚(LEADERは排除)</h2>
                    </div>
                    <div className="deck-cards-container-2">
                    {deck.cards.map(card => {
                        const fullCardInfo = cardDataMap[card.cardId]; // CardList から詳細情報を取得
                        if (fullCardInfo.type !== 'LEADER') { // リーダーカードを除外
                            return (
                                <div key={fullCardInfo.cardId} className="card-container-2" onClick={() => handleImageClick(fullCardInfo.image)}>
                                    <img src={fullCardInfo.image[0]} alt={fullCardInfo.name} />
                                    <h3>{fullCardInfo.name}</h3>
                                    <p>枚数: {card.quantity}</p>
                                </div>
                            );
                        }
                        return null;
                    })}
                    </div>
                    <ImageModal />

                    <button onClick={() => setShowDeckList(!showDeckList)}>
                        デッキレシピ表
                    </button>
                    {showDeckList && (
                       <div className="deck-list-container">
                       <table className="deck-list-table">
                         <thead>
                           <tr>
                             <th>カードNo</th>
                             <th>カード名</th>
                             <th>タイプ</th>
                             <th>枚数</th>
                           </tr>
                         </thead>
                         <tbody>
                         {deck.cards.map((card, index) => {
                                const fullCardInfo = cardDataMap[card.cardId];
                                return (
                                    <tr key={index}>
                                        <td>{fullCardInfo.cardId}</td>
                                        <td>{fullCardInfo.name}</td>
                                        <td>{fullCardInfo.type}</td>
                                        <td>{card.quantity}</td>
                                    </tr>
                                );
                            })}
                         </tbody>
                       </table>
                     </div>
                    )}
                    <div className="card mb-3">
                    <h2>デッキタイプ別 優勝レシピ</h2>
                    </div>
                    <p>Twitterで公開されたの{deck.deckName}の優勝レシピを紹介しています。</p>
                    
                </div>
            ) : (
                <p>デッキが見つかりません</p>
            )}
        </div>
    );
};

export default DeckDetail;