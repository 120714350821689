import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import tool from '../js/tool.json'; // JSON データのインポート
import {Tools} from '../types/types'
import '../AllDeckList/ListStyle.css'
import { Helmet } from 'react-helmet';

const ToolList = () => {
    const [tools, setTools] = useState<Tools[]>(tool as Tools[]);

    useEffect(() => {
        setTools(tool);
    }, []);

    return (
        <div>
            <Helmet>
                <title>ツール一覧 - ドラゴンボール フュージョンワールド デッキ 構築 ガイド</title>
                <meta name="description" content="ツールを提供するページです。"/>
                {/* ここに追加で構造化データなどのマークアップを挿入できます */}
            </Helmet>
            <div className="card mb-4">
            <h1>ツール一覧</h1>
            </div>
            <div className="deck-detail-list-2">
                {tools.map(tool => (
                    <div key={tool.toolName} className="deck-detail-2">
                        <h2>{tool.toolName}</h2>
                        <img src={tool.image} alt={tool.toolName} />
                        <Link to={tool.url}>詳細を見る</Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ToolList;
