import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CardItem, CardsProps,cardTypes, cardCosts, cardColors, cardPacks,cardRaritys } from './CardInterface';
import Slider from 'react-slick'; // react-slick をインポート
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import './CardStyle.css'
import Modal from 'react-bootstrap/Modal';


function Cards({ CardList, increment, decrement,countToFour,resetCount,resetPagination }: CardsProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [nameFilter, setNameFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState<string[]>([]);
  const [costFilter, setCostFilter] = useState<string[]>([]);
  const [colorFilter, setColorFilter] = useState<string[]>([]);
  const [packFilter, setPackFilter] = useState<string[]>([]);
  const [RarityFilter, setRarityFilter] = useState<string[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState(20); // デフォルト値
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const handleShowFilters = () => setShowFiltersModal(true);
  const handleCloseFilters = () => setShowFiltersModal(false);

  // 検索クエリに基づいてカードをフィルタリング
  const filteredCards = CardList.filter(card =>
    (nameFilter ? card.name.toLowerCase().includes(nameFilter.toLowerCase()) : true) &&
    (typeFilter.length === 0 || typeFilter.includes(card.type)) &&
    (costFilter.length === 0 || costFilter.includes(card.cost)) &&
    (colorFilter.length === 0 || colorFilter.includes(card.color)) &&
    (packFilter.length === 0 || packFilter.includes(card.pack)) &&
    (RarityFilter.length === 0 || RarityFilter.includes(card.rarity))
  );
  const indexOfFirstItem = (currentPage - 1) * itemsPerPage;
  const indexOfLastItem = Math.min(currentPage * itemsPerPage, filteredCards.length);
  // 現在のページのアイテムを計算
  const [currentItems, setCurrentItems] = useState<CardItem[]>([]);
  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
    currentFilter: string[],
    setFilter: React.Dispatch<React.SetStateAction<string[]>>) => {
    if (event.target.checked) {
      // チェックされた場合、値を配列に追加
      setFilter([...currentFilter, value]);
    } else {
      // チェックが外された場合、値を配列から削除
      setFilter(currentFilter.filter(item => item !== value));
    }
  };


  // ページネーションコントロール
  const totalPages = Math.ceil(filteredCards.length / itemsPerPage);
  const nextPage = () => setCurrentPage(currentPage => Math.min(currentPage + 1, totalPages));
  const prevPage = () => setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  
  
  const handleImageClick = (images: string[]) => {
    setSelectedImages(images); // 複数の画像を設定
    setIsModalOpen(true);
  };
  
  const ImageModal = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true
    };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  
  return (
    <div className={`modal ${isModalOpen ? 'show' : ''}`} tabIndex={-1} style={{ display: isModalOpen ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <Slider {...settings}>
              {selectedImages.map((image, index) => (
                <div key={index}>
                  <img src={image} className="img-fluid" alt={`Card number ${index + 1}`} />
                </div>
              ))}
            </Slider>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={closeModal}>閉じる</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const drawCard =(ctx: CanvasRenderingContext2D, card: CardItem, x: number, y: number)  => {
  const cardImage = new Image();
  cardImage.src = card.image[0];
  cardImage.onload = () => {
    // カードの背景
    ctx.fillStyle = '#fff';
    ctx.fillRect(x, y, 160, 220);
    // カードの画像
    ctx.drawImage(cardImage, x, y, 160, 220);
  };
};

useEffect(() => {
  // resetPaginationが変更されたときにcurrentPageを1にリセット
  setCurrentPage(1);
}, [resetPagination]);
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setItemsPerPage(15); // モバイルでは15個
      } else {
        setItemsPerPage(20); // デスクトップでは20個
      }
    };
  
    // イベントリスナーを登録
    window.addEventListener('resize', handleResize);
  
    // 初期ロード時のチェック
    handleResize();
  
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  
  

  useEffect(() => {
    // 現在のページの最初と最後のアイテムのインデックスを計算
    const newCurrentItems = filteredCards.slice(indexOfFirstItem, indexOfLastItem);
    if (JSON.stringify(newCurrentItems) !== JSON.stringify(currentItems)) {
      setCurrentItems(newCurrentItems);
    }
  }, [currentItems, currentPage, filteredCards, indexOfFirstItem, indexOfLastItem, itemsPerPage]);


  useEffect(() => {
    setCurrentPage(1);
  }, [nameFilter, typeFilter, costFilter, colorFilter, packFilter,RarityFilter]);
  
  // useEffectを使ってカードをCanvasに描画する
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        // 描画前にCanvasをクリア
        ctx.clearRect(0, 0, canvas.width, canvas.height);
  
        // カードリストをループしてCanvasに描画
        currentItems.forEach((card, index) => {
          // x, y はカードの描画位置
          const x = (index % 4) * 170; // 4列ごとに配置
          const y = Math.floor(index / 4) * 230; // 行の高さを調整
          drawCard(ctx, card, x, y);
        });
      }
    }
  }, [currentItems]); // CardListが更新されたら再描画


  return (
    <>
     <div className="search-rows">
      <input className="search-input"
        type="text"
        placeholder="カード名"
        value={nameFilter}
        onChange={(e) => setNameFilter(e.target.value)}
      />
        <button className="search-button"  onClick={handleShowFilters}>検索詳細</button>
      </div>

      <Modal className="my-modal-class" show={showFiltersModal} onHide={handleCloseFilters}>
    <Modal.Header closeButton>
      <Modal.Title>詳細検索</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <div className="filter-container">
      <div className="filter-group">
      <h4>タイプ</h4>
      {cardTypes.map((type) => (
        <div key={type} className="custom-checkbox">
          <input
            type="checkbox"
            id={type}
            checked={typeFilter.includes(type)}
            onChange={(e) => handleCheckboxChange(e, type, typeFilter, setTypeFilter)}
          />
          <label htmlFor={type}>{type}</label>
        </div>
      ))}
      </div>
      <div className="filter-group">
      <h4>コスト</h4>
      <div>
        {cardCosts.map((cost) => (
          <div key={cost} className="custom-checkbox">
            <input
              type="checkbox"
              id={`cost-${cost}`}
              checked={costFilter.includes(cost)}
              onChange={(e) => handleCheckboxChange(e, cost, costFilter, setCostFilter)}
            />
            <label htmlFor={`cost-${cost}`}>{cost}</label>
          </div>
        ))}
      </div>
      </div>
      <div className="filter-group">
      <h4>色</h4>
      <div>
        {cardColors.map((color) => (
          <div key={color} className="custom-checkbox">
            <input
              type="checkbox"
              id={`color-${color}`}
              checked={colorFilter.includes(color)}
              onChange={(e) => handleCheckboxChange(e, color, colorFilter, setColorFilter)}
            />
            <label htmlFor={`color-${color}`}>{color}</label>
          </div>
        ))}
      </div>
      </div>
      <div className="filter-group">
      <h4>収録弾</h4>
      <div>
        {cardPacks.map((pack) => (
          <div key={pack} className="custom-checkbox">
            <input
              type="checkbox"
              id={`pack-${pack}`}
              checked={packFilter.includes(pack)}
              onChange={(e) => handleCheckboxChange(e, pack, packFilter, setPackFilter)}
            />
            <label htmlFor={`pack-${pack}`}>{pack}</label>
          </div>
        ))}
      </div>
      </div>
      <div className="filter-group">
      <h4>レア度</h4>
      <div>
        {cardRaritys.map((rarity) => (
          <div key={rarity} className="custom-checkbox">
            <input
              type="checkbox"
              id={`rarity-${rarity}`}
              checked={RarityFilter.includes(rarity)}
              onChange={(e) => handleCheckboxChange(e, rarity, RarityFilter, setRarityFilter)}
            />
            <label htmlFor={`rarity-${rarity}`}>{rarity}</label>
          </div>
        ))}
      </div>
      </div>
      </div>
      </Modal.Body>
      <Modal.Footer>
      <button onClick={handleCloseFilters}>閉じる</button>
    </Modal.Footer>
  </Modal>
      <div className="pagination-controls">
        <button onClick={prevPage} disabled={currentPage === 1}>前へ</button>
        <span>Page {currentPage} of {totalPages}</span>
        <button onClick={nextPage} disabled={currentPage === totalPages}>次へ</button>
      </div>
      
      {currentItems.length === 0 ? (
      // 検索結果が0の場合
      <div>該当するカードが見つかりませんでした。</div>
    ) : (
    <div className="cards-grid">
    {currentItems.map((card) => (
      <div key={card.cardId} className="card-container">
        <canvas ref={el => {
          if (el) {
            const ctx = el.getContext('2d');
            if (ctx) {
              drawCard(ctx, card, 0, 0); // Canvasにカードを描画
            }
          }
        }} width={160} height={220} onClick={() => handleImageClick(card.image)} />
        <div className='font-size-name'>{card.name}</div>
        <div>{card.type}</div>
        <div className="card-controls">
          {/* 正しいインデックスを計算して関数に渡す */}
          {card.type === "LEADER" ? (
          // LEADERタイプのカードの場合
          <>
          <div className="button-group">
            <button className="button-large" onClick={() => increment(card.cardId)}>+</button>
            <div>
            <span className='pagination-controls-count'>{card.count}</span>
            </div>
            <button className="button-large" onClick={() => decrement(card.cardId)}>-</button>
            </div>
          </>
        ) : (
          // LEADER以外のカードの場合
          <>
          <div className="button-group">
            <button className="button-large" onClick={() => countToFour(card.cardId)}>max</button>
            <button className="button-large" onClick={() => increment(card.cardId)}>+</button>
            </div>
            <div className='pagination-controls-count'>{card.count}</div>
            <div className="button-group-down">
            <button className="button-large" onClick={() => resetCount(card.cardId)}>min</button>
            <button className="button-large" onClick={() => decrement(card.cardId)}>-</button>
            </div>
          </>
        )}
        </div>
      </div>
    ))}
  </div>
  )}
  <ImageModal />
  <div className="pagination-controls">
        <button onClick={prevPage} disabled={currentPage === 1}>前へ</button>
        <span>Page {currentPage} of {totalPages}</span>
        <button onClick={nextPage} disabled={currentPage === totalPages}>次へ</button>
      </div>
  </>
  );
}


 



export default Cards;
